import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// includes
import { api, common } from "helpers";
import { config } from "../../helpers/config";
export default function SignIn() {
  // init
  const navigate = useNavigate();
  // state
  const [loading, setloading] = useState(false);
  const [isView, setIsView] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });
  const [isAuth] = useState(common.checkAuth());

  // form
  const formFields = {
    email: {
      validate: ["req#Email is required"],
    },
    password: {
      validate: [
        "req#Password is required!",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    if (isAuth) {
      let authInfo = common.getAuth();
      navigate(authInfo?.role == "student" ? "/student/home" : "/agent/home");
    }
    common.tempDataRemove();
  }, []);

  // handler
  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      Login();
    }
  };

  // Api
  const Login = () => {
    setloading(true);
    let payload = fields;

    // api call
    let data = {
      url: "auth_login",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res) => {
      // return
      if (res.status === 200) {
        let data = res.data;

        if (data.role === "student") {
          common.localSet("authData", common.crypt(data, true));
          navigate("/student/home");
        } else {
          common.notify("E", "Please Login into agent portal");
        }
      } else if (res.status === 500) {
        if (res.data.code === "incorrect_password") {
          setError((prev) => ({ ...prev, password: res.data.error }));
        } else {
          setError((prev) => ({ ...prev, email: res.data.error }));
        }
        setloading(false);
      } else {
        common.notify("E", "Something went wrong, please try again");
      }
      setloading(false);
    });
  };

  // support
  const resetError = (name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <>
      <div className="auth-wrap student-auth-wrap">
        <div className="auth-logo-wrap">
          <a href={config.home_page}>
            <img
              src={common.loadImg("logo-purple.svg")}
              width="113"
              height="100"
              alt="study-uk"
            />
          </a>
        </div>
        <div className="signin-right student-signup-right">
          <div>
            <img
              // src="/imgs/app-section-img2.png"
              src={common.loadImg("app-section-img2.png")}
              width="500"
              height="520"
              alt="study-uk"
              className="novelty-app-img"
            />
          </div>
          <div>
            <div className="right-content">
              <p className="download-title-txt">
                "Download the Pickauni App: University Applications Made Easy"
              </p>
              <p className="download-txt1">Just for students.</p>
              <p className="download-txt2">
                Experience a hassle-free and seamless application process for
                your guaranteed University offer.
              </p>
              <p className="adv-txt1">
                Advantages associated with applying through Pickauni:
              </p>
              <ul className="download-list-txt">
                <li>
                  Enjoy complimentary meals for life as a student at popular
                  restaurants and takeaways.
                </li>
                <li>
                  Personalised, one-to-one support from experienced student
                  counsellors
                </li>
                <li>
                  Assistance with student finance applications and eligibility.
                </li>
                <li>
                  Support with personal statements, work references, CVs, and
                  other application materials.
                </li>
                <li>A guaranteed offer within 72 hours.</li>
              </ul>
              <p className="app-link-wrap">
                <a
                  href="https://apps.apple.com/us/app/novelty-card/id1628865738"
                  target="_blank"
                >
                  <img
                    src={common.loadImg("appstore-btn.svg")}
                    width="132"
                    height="44"
                    alt="app-btn"
                    className="me-1"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.noveltycarduser&pli=1"
                  target="_blank"
                >
                  <img
                    src={common.loadImg("playstore-btn.svg")}
                    width="132"
                    height="44"
                    alt="app-btn"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="left">
          <p className="title">
            <span>Sign in as a Student</span>
          </p>
          <div className="student-link-wrap">
            <p className="std-btns active ps-0">Sign in</p>
            <Link to="/student/register" className="std-btns ps-4">
              Sign up
            </Link>
          </div>
          <div>
            <div className="et-form-row form-wrap">
              <input
                type="email"
                name="email"
                placeholder="Username or Email"
                className="form-control input-email cust-input"
                maxLength={40}
                value={fields.email}
                onChange={(e) => {
                  resetError("email");
                  handleChange(e);
                }}
              />
              <div className="email-fieldcheckbox checkbox-design">
                <div className={"input-tick-active"}></div>
              </div>
              <p className="error-txt">
                {error.email ? error.email : errors.email}
              </p>
            </div>
            <div className="et-form-row">
              <input
                type={isView ? "text" : "password"}
                name="password"
                placeholder="Password"
                className="form-control cust-input"
                maxLength={15}
                onChange={(e) => {
                  resetError("password");
                  handleChange(e);
                }}
                value={fields.password}
              />
              <div className=" email-fieldcheckbox checkbox-design">
                <div className="input-tick-active"></div>
              </div>
              <div className="pwd-eye" onClick={() => setIsView(!isView)}>
                <img
                  src={common.loadImg(
                    isView ? "password_show.svg" : "password_hide.svg"
                  )}
                  alt="show"
                  className="et-cp"
                />
              </div>
              <p className="error-txt">
                {error.password ? error.password : errors.password}
              </p>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <Link
                to="/student/password/forgot"
                className="rememberlogin-text new-forgot-pwd"
              >
                Forgot Password
              </Link>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                className="form-control form-btn student-auth-btn"
                disabled={loading}
                onClick={onSubmit}
              >
                {loading ? "Processing" : "Sign In"}
              </button>
            </div>
          </div>
          <p className="policy-txt">
            We never share your data without permission. <br />
            Our privacy and cookie policies
          </p>
        </div>
      </div>
    </>
  );
}
