import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  useNavigate,
  useParams,
  useSearchParams,
  Link,
} from "react-router-dom";
// includes
import { api, common, config } from "helpers";
import PhoneInput from "react-phone-input-2";

function Register() {
  // init
  const navigate = useNavigate();
  const { type = "direct" } = useParams();

  // state
  const [viewA, setViewA] = useState(false);
  const [viewB, setViewB] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(null);
  const [queryParams] = useSearchParams();
  const [deviceToken, setDeviceToken] = useState(null);
  // form
  const formFields = {
    firstName: {
      validate: ["req#First Name is required", "min:3", "max:25"],
    },
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
        "max:60",
      ],
    },
    mobile: {
      validate: ["req#Mobile is required"],
    },
    password: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
      value: "",
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Password and confirm password do not match",
      ],
    },
    noveltyId: {},
    course: {},
    intakeMonth: {},
    intakeYear: {},
  };
  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // Api
  const register = () => {
    let payload = {
      name: fields?.firstName,
      email: fields?.email,
      mobile: fields?.mobile,
      password: fields?.password,
      userType: "STUDENT",
      webDevToken: deviceToken,
      registeredFrom: "Web",
    };
    let data = {
      url: "ids_sign_up",
      method: "POST",
      auth: "none",
      body: payload,
    };

    api.call(data, (res) => {
      if (res.status == 200) {
        let data = res.data;
        if (data?.token) {
          common.notify("S", "Registration has been successfully completed");
          common.localSet("authData", common.crypt(data, true));
          navigate("/student/home");
        } else {
          common.localSet("authData", common.crypt(data, true));
          navigate("/student/home");
        }
      } else if (res.status == 500) {
        setError(res.data.error);
      }
      setLoading(false);
    });
  };

  // handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      setLoading(true);
      register();
    }
  };

  // support
  const checkValid = () => {
    let qry = queryParams.get("q");
    if (type === "novelty" && !qry) {
      navigate("/login");
      return false;
    }
    return true;
  };

  //render
  return (
    <div className="auth-wrap auth-signup student-auth-wrap">
      <div className="auth-logo-wrap">
        <a href={config.home_page}>
          <img
            src={common.loadImg("logo-purple.svg")}
            width="113"
            height="100"
            alt="study-uk"
          />
        </a>
      </div>
      <div className="container-fluid signup-right student-signup-right mv-student-signup-right ">
        <img
          src={common.loadImg("signup-img1.png")}
          width="785"
          height="996"
          alt="study-uk"
          className="signup-img"
        />
        <div className="row signup-right-bottom">
          <div className="col-md-6 bolton-univ-wrap">
            <div className="inner-wrap">
              <img
                src={common.loadImg("bolton-univ-logo.png")}
                width="234"
                height="83"
                alt="study-uk"
                className="bolton-univ-img"
              />
              <p className="location-wrap">
                A new state-of-the-art campus in
                <br />
                <img
                  src={common.loadImg("location-icn.svg")}
                  width="23"
                  height="33"
                  alt="location-icn"
                  className="me-1"
                />
                <span>Manchestercity</span>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <ul className="list">
              <li>Just two days per week on campus</li>
              {/* <li>Evening and Weekend programmes</li> */}
              <li>Mature students welcome</li>
              {/* <li>A placement based onyour work experience</li> */}
            </ul>
            <div className="food-wrap">
              <div>
                <img
                  src={common.loadImg("food-for-life-img.png")}
                  width="112"
                  height="113"
                  alt="food-logo"
                />
              </div>
              <div>
                <p className="apply-txt">Apply with Novelty & Pickauni</p>
                <p className="download-txt">Download the Pickauni app</p>
                <p className="app-link-wrap">
                  <a
                    href="https://apps.apple.com/us/app/novelty-card/id1628865738"
                    target="_blank"
                  >
                    <img
                      src={common.loadImg("appstore-btn.svg")}
                      width="114"
                      height="39"
                      alt="app-btn"
                      className="me-1"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.noveltycarduser&pli=1"
                    target="_blank"
                  >
                    <img
                      src={common.loadImg("playstore-btn.svg")}
                      width="114"
                      height="39"
                      alt="app-btn"
                    />
                  </a>
                </p>
              </div>
            </div>
            {/* <p className="signup-bt-link">
              visit to book for an open day. <br />
              <a href="https://pickauni.com/" target="_blank">
                www.pickauni.com
              </a>
            </p> */}
          </div>
        </div>
      </div>
      <div className="left">
        <p className="title">
          <span>Sign up as a Student</span>
        </p>
        {/* <p className="title register-title">Register</p> */}
        <div className="student-link-wrap">
          <Link to="/student/login" className="std-btns">
            Sign in
          </Link>
          <p className="std-btns active">Sign up</p>
        </div>
        <div className="form-wrap auth-form-wrap">
          <div className="auth-form-container">
            <div className="signin-form-row">
              <input
                type="text"
                name="firstName"
                value={fields.firstName}
                onChange={handleChange}
                className="form-control cust-input"
                maxLength={25}
                placeholder="First Name"
              />
              <div className=" email-fieldcheckbox checkbox-design">
                <div className="input-tick"></div>
              </div>
              <p className="error-txt">{errors.firstName}</p>
            </div>
            <div className="signin-form-row">
              <OverlayTrigger
                show={type === "novelty" && focus === "email"}
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">
                    Required OTP verification if you change / add your email
                    address
                  </Tooltip>
                }
              >
                <input
                  type="email"
                  name="email"
                  value={fields.email}
                  onChange={(e) => {
                    handleChange(e);
                    setError(null);
                  }}
                  onFocus={() => setFocus("email")}
                  onBlur={() => setFocus(null)}
                  maxLength={60}
                  className="form-control cust-input"
                  placeholder="Email Address"
                />
              </OverlayTrigger>
              <div className=" email-fieldcheckbox checkbox-design">
                <div className="input-tick"></div>
              </div>
              <p className="error-txt">{error ?? errors.email}</p>
            </div>
            <div className="signin-form-row">
              <PhoneInput
                inputProps={{
                  name: "mobile",
                  // required: true,
                  // autoFocus: true,
                }}
                value={fields?.mobile}
                country={"gb"}
                onChange={(phone) => {
                  handleChange({ target: { name: "mobile", value: phone } });
                  setError(null);
                }}
              />
              <div className=" email-fieldcheckbox checkbox-design">
                <div className="input-tick"></div>
              </div>
              <p className="error-txt">{errors.mobile}</p>
            </div>

            <div className="signin-form-row">
              <input
                type={viewA ? "text" : "password"}
                name="password"
                value={fields.password}
                onChange={handleChange}
                maxLength={15}
                className="form-control cust-input"
                placeholder="Password"
              />
              <div
                className="pwd-eye"
                onClick={() => setViewA((prev) => !prev)}
              >
                <img
                  src={
                    viewA
                      ? common.loadImg("password_show.svg")
                      : common.loadImg("password_hide.svg")
                  }
                  alt={viewA ? "shown" : "hidden"}
                  className="et-cp"
                />
              </div>
              <div className=" email-fieldcheckbox checkbox-design">
                <div className="input-tick"></div>
              </div>
              <p className="error-txt">{errors.password}</p>
            </div>
            <div className="signin-form-row">
              <input
                name="confirmPassword"
                type={viewB ? "text" : "password"}
                value={fields.confirmPassword}
                onChange={handleChange}
                maxLength={15}
                className="form-control cust-input"
                placeholder="Confirm Password"
              />
              <div
                className="pwd-eye"
                onClick={() => setViewB((prev) => !prev)}
              >
                <img
                  src={
                    viewB
                      ? common.loadImg("password_show.svg")
                      : common.loadImg("password_hide.svg")
                  }
                  alt={viewB ? "shown" : "hidden"}
                  className="et-cp"
                />
              </div>
              <div className=" email-fieldcheckbox checkbox-design">
                <div className="input-tick"></div>
              </div>
              <p className="error-txt">{errors.confirmPassword}</p>
            </div>
            <div className="agree-txt">
              I agree to the Terms and Conditions by clicking Sign up
            </div>

            <div className="d-flex justify-content-center mt-2">
              <button
                className="form-control form-btn student-auth-btn"
                onClick={() => onSubmit()}
                disabled={loading}
              >
                {!loading ? "Sign up" : "Processing"}
              </button>
            </div>
          </div>
        </div>
        <p className="policy-txt">
          We never share your data without permission. <br />
          <a href="/privacyPolicy">Our privacy and cookie policies</a>
        </p>
      </div>
    </div>
  );
}

export default Register;
