import { Fragment, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Papa from "papaparse";
// includes
import AgTable from "elements/AgTable";
import ModalInfo from "elements/modalInfo";
import { api, common } from "helpers";
import VerifyModal from "elements/modalNotVerified";
import Accordion from "react-bootstrap/Accordion";
import studentImport from "assets/files/student_import.csv";

function List() {
  const navigate = useNavigate();
  const [authInfo] = useOutletContext();
  const [isModal, setIsModal] = useState(null);
  const [user, setUser] = useState({});
  const [reload, setReload] = useState(new Date());
  const [infoModal, setInfoModal] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    if (authInfo?.role !== "student") {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            setUser(res.data);
          }
        }
      );
    }
  };

  // file handler
  const onFileChange = async (e) => {
    const file = e.target.files[0];

    Papa.parse(file, {
      complete: (result) => {
        let data = result.data;
        let header = data[0].map(function (x) {
          return x.toUpperCase();
        });

        if (data.length > 0 && header.includes("EMAIL")) {
          fileUpload(file, data.length);
        } else {
          if (data.length == 0) {
            common.notify("E", "No data found to import!");
          } else {
            common.notify("E", "Invalid format!");
          }
        }
      },
    });
  };

  const fileUpload = async (file, total) => {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("type", "excel");

    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      ssr: true,
      cType: 6,
    };

    let resultData = await api.call(data);
    if (resultData.status === 200) {
      let payload = {
        url: "student_import",
        method: "POST",
        body: { file: resultData.data.link[0] },
      };

      api.call(payload, (uploadData) => {
        if (uploadData?.status === 200) {
          let result = uploadData.data;
          let existingRecords = result.data.agentExList.concat(
            result.data.stuExList
          );
          let invalidRecords = result.data?.invalidEmail || [];

          if (result.data.studentList.length > 0) {
            setReload(new Date());
            common.notify("S", "Imported Successfully!");
          } else {
            setInfoModal({
              title: "Upload Report",
              message: [
                <div>
                  <div className="et-modal-body text-center">
                    <div className="mb-4">
                      {result.data.studentList.length == 0
                        ? "Upload failed. Please correct the following records with valid format"
                        : `${result.data.studentList.length} out of ${total} ${
                            result.data.studentList.length == 1
                              ? "record is"
                              : "records are"
                          } uploaded.`}
                    </div>
                    {invalidRecords.length > 0 && (
                      <>
                        <h4>Invalid Records</h4>
                        <ul>
                          {invalidRecords.map((obj, idx) => (
                            <li key={idx}>{obj?.Email}</li>
                          ))}
                        </ul>
                      </>
                    )}
                    {existingRecords.length > 0 && (
                      <>
                        <h4>Duplicate Records</h4>
                        <ul>
                          {existingRecords.map((obj, idx) => (
                            <li key={idx}>{obj?.Email}</li>
                          ))}
                        </ul>{" "}
                      </>
                    )}
                  </div>
                  <div className="et-modal-footer p-3">
                    <div className="d-flex justify-content-center">
                      <button
                        className="register-step-btn"
                        onClick={() => close()}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>,
              ],
              footerHide: true,
            });
          }
        } else {
          console.log("error data", JSON.stringify(uploadData.data.error));
          common.notify("E", "Import failed.");
        }
      });
    } else {
      common.notify("E", "Import failed.");
    }
  };

  const close = () => {
    setInfoModal(null);
  };

  const onAction = (data) => {
    if (data.type === "Add") {
      navigate(`/agent/student/add`);
    } else if (data.type === "Edit") {
      if (
        authInfo.role == "sub_agent" &&
        user &&
        user?.permissions?.length > 0
      ) {
        if (
          user?.permissions[0]?.stuView == "stuall" &&
          user?.permissions[0]?.stuUpdate == "stumy"
        ) {
          if (data?.item?.subAgentId && data?.item?.subAgentId === user?._id) {
            navigate(`/agent/student/edit/${data.item._id}/personal_address/2`);
          } else {
            common.notify("E", "Edit Access Not Allowed");
          }
        } else {
          navigate(`/agent/student/edit/${data.item._id}/personal_address/2`);
        }
      } else {
        navigate(`/agent/student/edit/${data.item._id}/personal_address/2`);
      }
    } else if (data.type === "View") {
      if (data.module == "agentStudent") {
        navigate(`/agent/student/view/${data.item._id}`);
      } else {
        navigate(`/agent/promoters/student/info/${data.item._id}`);
      }
    } else if (data.type === "Apply") {
      navigate(`/course/finder/p/${data.item._id}`);
    }
  };

  const userVerify = (val) => {
    if (authInfo?.role === "agent") {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            if (res?.data.isVerified) {
              navigate(`/agent/student/add`);
            } else {
              setIsModal(true);
            }
          }
        }
      );
    } else {
      navigate(`/agent/student/add`);
    }
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex justify-content-between align-items-center py-2">
        <h1 className="page-title">Students</h1>
        {/* <Link to="/agent/student/add"  className="text-dec-none"> */}

        <div className="w-100 d-flex justify-content-end">
          <a
            href={studentImport}
            download="student_import.csv"
            className="btn-signin d-flex justify-content-end align-items-center me-2 text-dec-none"
          >
            <span className="ms-3">Download Format</span>
            <img
              src={common.loadImg("download-circle-button.svg")}
              className="me-3"
              alt="promotion-icn"
            ></img>
          </a>
          <input
            type="file"
            id="file-1"
            className="et-hide"
            name="student"
            value=""
            accept=".csv"
            multiple={false}
            onChange={(e) => onFileChange(e)}
          />
          <label
            className="btn-signin d-flex justify-content-between align-items-center"
            type="button"
            htmlFor="file-1"
            id="button-addon2"
          >
            <span className="ms-3">Import</span>
            <img src={common.loadImg("plus-sign.svg")} className="me-3" />
          </label>

          <button
            onClick={() => userVerify()}
            className="btn-signin d-flex justify-content-between align-items-center ms-2"
          >
            <span className="ms-3">New Student</span>
            <img
              src={common.loadImg("plus-sign.svg")}
              className="me-3"
              alt="promotion-icn"
            ></img>
          </button>
        </div>

        {/* </Link> */}
      </div>
      <div className="content-wrap">
        {user?.isPromotor || user?.agent?.isPromotor ? (
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>My Students</Accordion.Header>
              <Accordion.Body>
                {/* <h1 className="page-title">My Student</h1> */}
                <AgTable
                  module="agentStudent"
                  filterData={{
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }}
                  onAction={onAction}
                />
              </Accordion.Body>
            </Accordion.Item>
            {/* {(user?.isPromotor || user?.agent?.isPromotor) && */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>Agent Students</Accordion.Header>
              <Accordion.Body>
                <AgTable
                  module="coordinatorAgentStudent"
                  filterData={{
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }}
                  onAction={onAction}
                />
              </Accordion.Body>
            </Accordion.Item>
            {/* } */}
          </Accordion>
        ) : (
          <AgTable
            module="agentStudent"
            filterData={{
              dateA: {
                key: "startDate",
                value: "",
                title: "Created Date From",
              },
              dateB: { key: "endDate", value: "", title: "Created Date To" },
            }}
            reload={reload}
            onAction={onAction}
          />
        )}
      </div>

      {isModal && <VerifyModal onClose={() => setIsModal(null)} />}
      {infoModal !== null && (
        <ModalInfo data={infoModal} onYes={() => close()} />
      )}
    </Fragment>
  );
}

export default List;
