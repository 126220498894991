import moment from "moment-timezone";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
// includes
import { api, common } from "helpers";
import { Password } from "@mui/icons-material";

export default function Otp() {
  // init
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = useParams();

  // state
  const [init, setInit] = useState(false);
  const [localData] = useState(common.localDataGet());
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [timeOut, setTimeOut] = useState(59);
  const [loading, setLoading] = useState(false);

  // effect
  useEffect(() => {
    if (!localData) {
      if (type == "student") {
        navigate("/student/login");
      } else {
        navigate("/agent/login");
      }
    } else {
      setInit(true);
    }
  }, []);

  useEffect(() => {
    const timer =
      timeOut > 0 && setInterval(() => setTimeOut(timeOut - 1), 1000);
    return () => clearInterval(timer);
  }, [timeOut]);

  // api
  const onSubmit = () => {
    if (otp.length < 6) {
      setError("Enter the otp");
    } else {
      setLoading(true);
      // call
      let data =
        localData.type === "passwordForgot"
          ? {
              url: "auth_otp_verify",
              method: "POST",
              auth: "none",
              body: {
                email: localData.email,
                otp: otp,
                timeZone: moment.tz.guess(),
                type: "verify",
              },
            }
          : {
              url: "auth_otp_verify",
              method: "POST",
              auth: "none",
              body: {
                otp: otp,
                type: "login",
              },
            };

      api.call(data, (res) => {
        if (res.status === 200) {
          let data = res.data;
          if (localData.type === "passwordForgot") {
            common.localDataSet({
              email: localData.email,
              resetToken: data.resetToken,
              otp,
            });
            if (type == "student") {
              navigate("/student/password/reset");
            } else {
              navigate("/agent/password/reset");
            }
          } else if (localData.type === "studentRegister") {
            common.notify("S", "Registration has been successfully completed");
            common.localSet("authData", common.crypt(data, true));
            navigate("/student/home");
          }
        } else if (res.status === 500) {
          setError(res.data.error);
        }
        setLoading(false);
      });
    }
  };

  const otpGenerator = () => {
    let data =
      localData.type === "passwordForgot"
        ? {
            url: "auth_otp_generate",
            method: "POST",
            auth: "none",
            body: {
              email: localData.email,
              type: "forgot",
            },
          }
        : {
            url: "agent_register",
            method: "POST",
            auth: "none",
            body: {
              ...localData,
              type: "register",
            },
          };
    api.call(data, (res) => {
      if (res.status === 200) {
        setTimeOut(59);
        common.notify("S", "Otp has been sent to " + localData.email);
      } else if (res.status === 500) {
        setError(res.data.error);
      }
    });
  };

  // support
  const goBack = (e) => {
    e.preventDefault();
    if (localData.type === "passwordForgot") {
      common.localDataSet({
        email: localData.email,
      });
    } else if (localData.type === "studentRegister") {
      common.localDataSet(localData);
    }
    navigate(-1);
  };

  // render
  return (
    init && (
      <>
        {/* <div className="container-fluid">
        <div className="row">
          <div className="col-12 et-page-wrap otp-page-wrap">
            <div className="row">
              <div className="col-md-5 mx-auto">
                <div className="box-header">
                  <p className="title">OTP Verification</p>
                </div>
                <div className="otp-body">
                  <div>
                    <label className="form-label">
                      OTP sent to <b>{localData?.email}</b>
                    </label>
                  </div>
                  <div className="otp-section d-flex justify-content-center">
                    <OtpInput
                      value={otp}
                      onChange={(value) => {
                        setError(null);
                        setOtp(value);
                      }}
                      numInputs={6}
                      isInputNum={true}
                      className={error && "otp-error"}
                      inputStyle={{
                        width: "54px",
                        height: "54px",
                        color: "#000",
                        fontWeight: "400",
                        fontSize: "22px",
                        caretColor: "blue",
                      }}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div className="mx-5">
                    <p className="error-txt">{error}</p>
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <div className="d-flex">
                      <p className="rememberlogin-text">Didn't receive code?</p>
                    </div>
                    <div>
                      {timeOut ? (
                        <p>Resend OTP in 0:{timeOut} </p>
                      ) : (
                        <a
                          onClick={otpGenerator}
                          className="rememberlogin-text"
                        >
                          Resend code
                        </a>
                      )}
                    </div>
                  </div>

                  <div className=" justify-content-between mt-3">
                    <div>
                      <a
                        href="#"
                        className="rememberlogin-text"
                        onClick={goBack}
                      >
                        Back
                      </a>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className={loading ? "btn-signin-disable" : "btn-signin"}
                      disabled={loading ? loading : false}
                      onClick={onSubmit}
                    >
                      {loading ? "Processing" : "Submit"}
                    </button>
                  </div>
                </div>

                <div className="shadow-div"></div>
                <div className="greenshow-div"></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        {location?.pathname === "/agent/otp/verify" && (
          <div className="container-fluid p-0">
            <div className="auth-wrap promoter-wrap auth-signup ">
              <div className="signup-right">
                <div className="inner-wrapper">
                  <h2 className="title mb-0">No false promises</h2>
                  <h1 className="title sub mb-0">Realistic Income</h1>
                  <p>
                    Believe in yourself to <br /> Achieve what is possible for
                    you!
                  </p>
                  <div className="px-5">
                    <img
                      src={common.loadImg("weight-down.svg")}
                      width="368"
                      height="343"
                      alt="weight1"
                      className="weight-down-img"
                    />
                  </div>
                  <img
                    src={common.loadImg("weight-up.svg")}
                    width="397"
                    height="688"
                    alt="weight1"
                    className="weight-up-img"
                  />
                </div>
                <div className="agent-signup-right">
                  <img
                    src={common.loadImg("logo-web.svg")}
                    width="115"
                    height="57"
                    alt="study-uk"
                  />
                </div>
                <div>
                  <p className="promoter-siginup-foot-txt">
                    Register as a Agent for Pickauni and earn a{" "}
                    <strong>realistic £50 - £100k per year.*</strong>
                  </p>
                  <p className="more-link-txt">
                    Click the link below to find out more
                  </p>
                </div>
              </div>
              <div className="bottom-bg"></div>
              <div className="left">
                <p className="title register-title mb-5">OTP Verification</p>
                {/* <div className="d-flex justify-content-start mb-5">
                {Array(6)
                  .fill()
                  .map((i, k) => {
                    ++k;
                    return (
                      <div key={k} className="cp" onClick={() => gotoStep(k)}>
                        <p className="register-steps-label web-steps-label me-2">
                          <span
                            className={`dotbtn${
                              step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                            }`}
                            data-title={tooltip[k - 1]}
                          >
                            {k}
                          </span>
                          <span
                            className={`reg-steps-label${
                              step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                            }`}
                          >
                            {tooltip[k - 1]}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                <p
                  id="tool-tip"
                  className="et-cp"
                  data-tooltip-content="This is Tooltip"
                ></p>
                <ReactTooltip
                  anchorId="tool-tip"
                  place={"bottom"}
                  style={{ zIndex: "999" }}
                />
              </div> */}
                <div className="form-wrap auth-form-wrap">
                  <div className="auth-form-container mt-5">
                    <div className="text-center pb-3">
                      <label className="auth-otp-label">
                        OTP sent to your <strong>{localData?.email}</strong>
                      </label>
                    </div>
                    <div className="otp-section d-flex justify-content-center">
                      <OtpInput
                        value={otp}
                        onChange={(value) => {
                          setError(null);
                          setOtp(value);
                        }}
                        numInputs={6}
                        isInputNum={true}
                        className={error && "otp-error"}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                          width: "60px",
                          height: "74px",
                          color: "#000",
                          fontWeight: "400",
                          fontSize: "22px",
                          caretColor: "blue",
                          borderRadius: "10px",
                        }}
                        renderSeparator={<span>-</span>}
                        // separator={<span>-</span>}
                      />
                    </div>
                    <div className="mx-5">
                      <p className="error-txt">{error}</p>
                    </div>

                    <div className="d-flex justify-content-between mt-4">
                      <div className="d-flex">
                        <p className="auth-otp-label">Didn't receive code?</p>
                      </div>
                      <div>
                        {timeOut ? (
                          <p className="auth-otp-label">
                            Resend OTP in 0:{timeOut}{" "}
                          </p>
                        ) : (
                          <a
                            onClick={otpGenerator}
                            className="auth-otp-label cp"
                          >
                            Resend OTP
                          </a>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className={
                          loading
                            ? "form-control form-btn promoter-auth-btn"
                            : "form-control form-btn promoter-auth-btn"
                        }
                        disabled={loading ? loading : false}
                        onClick={onSubmit}
                      >
                        {loading ? "Processing" : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {location?.pathname === "/student/otp/verify" && (
          <div className="auth-wrap student-auth-wrap">
            <div className="auth-logo-wrap">
              <Link to="/">
                <img
                  src={common.loadImg("logo-purple.svg")}
                  width="113"
                  height="100"
                  alt="study-uk"
                />
              </Link>
            </div>
            <div className="signin-right student-signup-right">
              <div>
                <img
                  // src="/imgs/app-section-img2.png"
                  src={common.loadImg("app-section-img2.png")}
                  width="500"
                  height="520"
                  alt="study-uk"
                  className="novelty-app-img"
                />
              </div>
              <div>
                <div className="right-content">
                  <p className="download-title-txt">
                    "Download the Pickauni App: University Applications Made
                    Easy"
                  </p>
                  <p className="download-txt1">Just for students.</p>
                  <p className="download-txt2">
                    Experience a hassle-free and seamless application process
                    for your guaranteed University offer.
                  </p>
                  <p className="adv-txt1">
                    Advantages associated with applying through Pickauni:
                  </p>
                  <ul className="download-list-txt">
                    <li>
                      Enjoy complimentary meals for life as a student at popular
                      restaurants and takeaways.
                    </li>
                    <li>
                      Personalised, one-to-one support from experienced student
                      counsellors
                    </li>
                    <li>
                      Assistance with student finance applications and
                      eligibility.
                    </li>
                    <li>
                      Support with personal statements, work references, CVs,
                      and other application materials.
                    </li>
                    <li>A guaranteed offer within 72 hours.</li>
                  </ul>
                  <p className="app-link-wrap">
                    <a
                      href="https://apps.apple.com/us/app/novelty-card/id1628865738"
                      target="_blank"
                    >
                      <img
                        src={common.loadImg("appstore-btn.svg")}
                        width="132"
                        height="44"
                        alt="app-btn"
                        className="me-1"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.noveltycarduser&pli=1"
                      target="_blank"
                    >
                      <img
                        src={common.loadImg("playstore-btn.svg")}
                        width="132"
                        height="44"
                        alt="app-btn"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="left">
              <p className="title">
                <span>OTP Verification</span>
              </p>
              <div className="auth-form-container mt-5">
                <div className="text-center pb-3">
                  <label className="auth-otp-label">
                    OTP sent to your <strong>{localData?.email}</strong>
                  </label>
                </div>
                <div className="otp-section d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={(value) => {
                      setError(null);
                      setOtp(value);
                    }}
                    numInputs={6}
                    isInputNum={true}
                    className={error && "otp-error"}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      width: "60px",
                      height: "74px",
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "22px",
                      caretColor: "blue",
                      borderRadius: "10px",
                    }}
                    renderSeparator={<span>-</span>}
                  />
                </div>
                <div className="mx-5">
                  <p className="error-txt">{error}</p>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <div className="d-flex">
                    <p className="auth-otp-label">Didn't receive code?</p>
                  </div>
                  <div>
                    {timeOut ? (
                      <p className="auth-otp-label">
                        Resend OTP in 0:{timeOut}{" "}
                      </p>
                    ) : (
                      <a onClick={otpGenerator} className="auth-otp-label cp">
                        Resend OTP
                      </a>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="form-control form-btn student-auth-btn"
                    disabled={loading ? loading : false}
                    onClick={onSubmit}
                  >
                    {loading ? "Processing" : "Submit"}
                  </button>
                </div>
              </div>
              <p className="policy-txt">
                We never share your data without permission. <br />
                Our privacy and cookie policies
              </p>
            </div>
          </div>
        )}
      </>
    )
  );
}
