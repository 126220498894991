import { AES, enc } from "crypto-js";
import moment from "moment";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// includes
import currency from "assets/files/currency.json";
import { config, types } from "helpers";

export const common = {
  loadImg(img) {
    let result = require("../assets/img/" + img);
    return result; //.default;
  },

  cloudImg(img) {
    let result = config.api.cloudImgPath + img;
    return result; //.default;
  },

  openCloudUrl(url) {
    window.open(config.api.cloudImgPath + url, "_blank");
  },

  fileName(file) {
    if (file && file.length) {
      let ext = file.substring(file.lastIndexOf("."));
      let fileName = file.split("_").slice(0, -1).join("_") + ext;
      return fileName.substring(fileName.indexOf("/") + 1);
    }
    return "";
  },

  notify(type, msg) {
    toast.dismiss();
    switch (type) {
      case "S":
        toast.success(msg, config.elements.toast);
        break;
      case "W":
        toast.warning(msg, config.elements.toast);
        break;
      case "E":
      case "E":
        toast.error(msg, config.elements.toast);
        break;
      case "I":
        toast.info(msg, config.elements.toast);
        break;
      case "M":
        toast(msg, config.elements.toast);
        break;
    }
  },

  // local storage
  localSet(key, value) {
    if (typeof value === "object") {
      Cookies.set(key, JSON.stringify(value), {
        domain: ".pickauni.in",
        path: "/",
      });
      Cookies.set(key, JSON.stringify(value), {
        domain: "localhost",
        path: "/",
      });
    } else {
      Cookies.set(key, value, {
        domain: "localhost",
        path: "/",
      });
      Cookies.set(key, value, {
        domain: ".pickauni.in",
        path: "/",
      });
    }
  },
  localGet(key, json = false) {
    let value = Cookies.get(key);
    if (value && json) {
      value = JSON.parse(value);
    }
    return value;
  },

  localRemove(key) {
    Cookies.remove(key, {
      domain: ".pickauni.in",
      path: "/",
    });
    Cookies.remove(key, {
      domain: "localhost",
      path: "/",
    });
  },

  // local data
  localDataSet(value) {
    this.localSet("localData", value);
  },

  localDataGet() {
    let res = this.localGet("localData", true);
    this.localDataRemove();
    return res;
  },

  localDataRemove() {
    return this.localRemove("localData");
  },

  // tempdata
  tempDataSet(value) {
    this.localSet("tempData", common.crypt(value, true));
  },

  tempDataGet() {
    let data = this.localGet("tempData");
    if (data) {
      return this.deCrypt(data, true);
    }
    return null;
  },

  tempDataRemove() {
    return this.localRemove("tempData");
  },

  // auth
  checkAuth() {
    let authData = this.localGet("authData");
    if (authData == "" || authData == null) {
      return false;
    } else {
      return this.getAuth() ? true : false;
    }
  },

  getAuth() {
    let data = this.localGet("authData");
    let decData = this.deCrypt(data, true);
    //return JSON.parse(decData);
    return decData;
  },

  // encode decode
  crypt(str, json = false) {
    if (json) {
      str = JSON.stringify(str);
    }
    return AES.encrypt(str, config.cryptKey).toString();
  },

  deCrypt(str, json = false) {
    try {
      let bytes = AES.decrypt(str, config.cryptKey);
      let data = bytes.toString(enc.Utf8);
      if (data == "") {
        Cookies.remove("authData");
        Cookies.remove("authData", {
          domain: ".pickauni.in",
          path: "/",
        });
        Cookies.remove("authData", {
          domain: "localhost",
          path: "/",
        });
        window.location.href = config.home_page;
      } else {
        // if (json) {
        //   return JSON.parse(data);
        // } else {
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        return data;
        // }
      }
    } catch (err) {
      Cookies.remove("authData", {
        domain: ".pickauni.in",
        path: "/",
      });
      Cookies.remove("authData", {
        domain: "localhost",
        path: "/",
      });
      window.location.href = config.home_page;
    }
  },

  // validation
  isEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  hideEmail(email) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  },

  hideMobile(phone) {
    if (phone.length > 5) {
      let prefixLength = 2;
      let suffixLength = 3;

      let prefix = phone.substring(0, prefixLength);
      let suffix = phone.slice(-suffixLength);
      let nbStars = phone.length - (prefixLength + suffixLength);

      let formattedPhone = prefix + "*".repeat(nbStars) + suffix;
      return formattedPhone;
    } else {
      return phone;
    }
  },

  getMonthList() {
    return [
      { label: "Jan", value: "1" },
      { label: "Feb", value: "2" },
      { label: "Mar", value: "3" },
      { label: "Apr", value: "4" },
      { label: "May", value: "5" },
      { label: "June", value: "6" },
      { label: "July", value: "7" },
      { label: "Aug", value: "8" },
      { label: "Sep", value: "9" },
      { label: "Oct", value: "10" },
      { label: "Nov", value: "11" },
      { label: "Dec", value: "12" },
    ];
  },

  SetMultiSelect(data, label, value, string = false) {
    let optionsData = [];
    if (data && data.length) {
      data.map((obj) => {
        let options = {
          label: obj[label],
          value: string ? obj[value].toString() : obj[value],
        };
        optionsData.push(options);
      });
    }
    return optionsData;
  },

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  },

  SetSelect(val) {
    return { label: val, value: val };
  },

  FindSelect(data, val) {
    let result = data.find((obj) => obj.value === val);
    return result ?? null;
  },

  FindMultiSelect(data, val) {
    let result = data.find((obj) => val.includes(obj.value));
    return result ?? null;
  },

  getFullYear(val) {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(val), (val, index) => index + year);
    return years;
  },

  paging(paging, type) {
    if (type == "offset") {
      return (paging.page - 1) * paging.limit;
    } else if (type == "serial") {
      return (paging.page - 1) * paging.limit;
    }
  },

  reParse(data) {
    return JSON.parse(JSON.stringify(data));
  },

  scrollToRef(myRef, adjust = 0) {
    window.scroll({
      top: myRef.current.offsetTop - adjust,
      behavior: "smooth",
    });
  },

  scrollTo(to = "") {
    if (to === "top") {
      window.scroll({ top: 0, behavior: "smooth" });
    } else if (to === "bottom") {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  },

  // validate
  validateType(val, type = "s") {
    let result = val;
    if (type == "n") {
      result = common.onlyNumbers(val);
    } else if (type == "f") {
      result = common.onlyFloat(val);
    }
    return result;
  },

  validateMobile(number) {
    return number.replace(/\D/g, "");
  },

  // input
  onlyNumbers(str) {
    return str.replace(/[^0-9]+/gi, "");
  },

  onlyFloat(str) {
    return str.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
  },

  modalStyle(width = 75) {
    return {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: `${width}%`,
        maxHeight: "800px",
        marginRight: "-50%",
        padding: "0",
        borderRadius: "0",
        border: "none",
        boxShadow: "0px 0px 20px rgba(77, 78, 141, 0.1773601)",
        transform: "translate(-50%, -50%)",
      },
    };
  },

  mapStyle() {
    return {
      boxSizing: `border-box`,
      border: `1px solid transparent`,
      width: `300px`,
      height: `50px`,
      borderRadius: `3px`,
      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
      fontSize: `14px`,
      outline: `none`,
      textOverflow: `ellipses`,
      position: "absolute",
      left: "48%",
      marginLeft: "-120px",
    };
  },
  sliderSettings() {
    return {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },

  ctaSliderSettings() {
    return {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },

  signupSliderSettings() {
    return {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },

  redirect(url = "") {
    window.location.href = config.public_url + url;
  },

  dateFormat(val, format) {
    return val ? moment(val).format(config.elements.dp[format]) : "";
  },

  trimString(str, len) {
    let res = str;
    if (str?.length > len) {
      res = str.substring(0, len) + "...";
    }
    return res;
  },

  readable(type, str) {
    let res = "";
    if (str === null || str === "" || str === "undefined") {
      res = " - ";
    } else {
      if (type === "string") {
        res = str;
      } else if (type === "date") {
        res = moment(str).format(config.elements.dp.A);
      } else if (type === "dateTime") {
        res = moment(str).format(config.elements.dp.C);
      } else if (type === "select") {
        res = str?.name;
      } else if (type === "boolObject") {
        res = str ? "Yes" : "No";
      } else if (type === "nationality") {
        res = str?.nationality;
      }
    }
    return res;
  },

  progressMeter(val) {
    let result = "error";
    if (val >= 30 && val < 50) {
      result = "warning";
    } else if (val >= 50 && val <= 99) {
      result = "info";
    } else if (val === 100) {
      result = "success";
    }
    return result;
  },

  getPath(url) {
    let res = url
      ?.split("/")
      ?.filter((i) => i != "")
      ?.slice(0, 2);
    if (["application"].includes(res[0])) {
      return res[0];
    } else if (["course"].includes(res[0])) {
      return res[0];
    } else {
      return res?.join("_");
    }
  },

  filterSet(filterData, data) {
    let fData = {};
    let nData = common.reParse(filterData);
    Object.entries(nData).map(([key, item]) => {
      let type = types.filterFields[key].type;
      if (type === "array") {
        // console.log(data[key], data, { key });
        fData[item.key] = data[key]?.map((i) => i?.value);
      } else if (type === "object") {
        fData[item.key] = data[key] ? data[key]?.value : "";
      } else if (type === "boolObject") {
        fData[item.key] = data[key] ? data[key]?.value : "";
      } else if (type === "date") {
        fData[item.key] = data[key]
          ? moment(data[key]).format(config.elements.dp.A)
          : "";
      } else if (type === "twoArray") {
        fData[item.key] = [data[key][0], data[key][1]];
      }
    });
    return fData;
  },

  filterParse(data, useKey = false) {
    let fData = {};

    let nData = common.reParse(data);

    Object.entries(nData).map(([key, item]) => {
      let finder = types.filterFields[key].finder;
      if (useKey && finder) {
        fData[useKey ? key : item.key] = common.FindSelect(
          types[finder],
          item.value
        );
      } else {
        fData[useKey ? key : item.key] = item.value;
      }
    });
    // console.log({ fData });

    return fData;
  },

  filterReset(data, useKey = false) {
    let fData = {};
    let nData = common.reParse(data);

    Object.entries(nData).map(([key, item]) => {
      fData[useKey ? key : item.key] = types.filterFields[key].value;
    });
    return fData;
  },

  maskString(str, f, l) {
    var first = str.substring(0, f);
    var last = str.substring(str.length - l);
    let mask = str.substring(f, str.length - l).replace(/./g, "*");
    return first + mask + last;
  },
  ArrayJsonFind(key, value, Array, getObject = false) {
    let hashValue = false;
    let Object = {};
    Array.map((item, index) => {
      if (item[key] === value) {
        hashValue = true;
        Object = item;
        Object.index = index;
      }
    });
    if (getObject) {
      return Object;
    } else {
      return hashValue;
    }
  },

  currencyToSymbol(currencyCode) {
    return currency[currencyCode].symbol;
  },
  numberWithCommas(value) {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  },
  getRepeatedObj(arr, key, value) {
    let captureRep = [];
    arr.map((item) => {
      if (item[key] === value) {
        captureRep.push(item[key]);
      }
    });

    return captureRep;
  },
  urlQueryToJson(str) {
    let resp = {};
    let uStr = str.split("&");
    uStr.map((item) => {
      let sp = item.split("=");
      if (sp.length === 3) {
        resp[sp[1]] = sp[2];
      } else if (sp.length === 2) {
        resp[sp[0]] = sp[1];
      }
    });

    return resp;
  },
  getPermission() {
    let result = [];
    types.permission.list.map((i) =>
      i.types.map((j) => {
        result.push(i.key + j);
      })
    );
    return result;
  },
  generateString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%:";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
};
