import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
/* import { GoogleOAuthProvider } from '@react-oauth/google'; */
// package css
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-18-image-lightbox/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// internal css
import "./assets/css/app.css";
import "./assets/css/component.css";
import "./assets/css/custom.css";
import "./assets/css/table.css";
import "./assets/css/dev.css";
import "./assets/css/media.css";
import "./assets/css/theme.css";
import "./assets/css/course-finder.css";
import "./assets/css/layout.css";
import "./assets/css/cropit.css";
import "./assets/css/view-info.css";
import "./assets/css/stripe.css";
import "./assets/css/timeline.css";
import "./assets/css/responsive.css";
import "./assets/css/subscription.css";
import "./assets/css/auth.css";

// Routers
import App from "./App";
import moment from "moment-timezone";
// render
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Fragment>
    {/* <GoogleOAuthProvider clientId={'1016858398368-t0dbo9til2mvkgpunj3bj51tr052g7cm.apps.googleusercontent.com'}> */}
    <App />
    <ToastContainer />
    {/* </GoogleOAuthProvider> */}
  </Fragment>
);
console.log(moment.tz.guess() + " - Version 1.0");
