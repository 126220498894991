import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
// includes
import FieldSelect from "elements/fieldSelect";
import StatusBar from "elements/statusBar";
import { api, common } from "helpers";

function ConnectToAgent(props) {
  // const
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("empty");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [connectId, setConnectId] = useState("");
  const [fields, setFields] = useState({
    country: null,
    province: null,
    city: null,
  });
  const [selectRender, setSelectRender] = useState({
    country: "yes",
    province: "no",
    city: "no",
  });

  //
  useEffect(() => {
    // getAgentList();
    getSalesPerson();
  }, [selectRender]);

  // api
  const getAgentList = () => {
    setConnectId("");
    setStatus("process");
    let status = "success";
    let params = {
      country: fields.country?.value,
      province: fields.province?.value || "",
      city: fields.city?.value || "",
    };
    let data = {
      url: "student_connect",
      method: "GET",
      query: `?query=${JSON.stringify(params)}&offset=1&limit=1000`,
    };
    api.call(data, (res) => {
      if (res.status !== 200) {
        status = "error";
      } else {
        if (res.data.data.length === 0) {
          status = "empty";
        }
        console.log("========agent", res.data);
        setResult(res.data);
      }
      setStatus(status);
    });
  };

  const getSalesPerson = () => {
    setConnectId("");
    setStatus("process");
    let status = "success";
    let params = {
      country: fields.country?.value,
      state: fields.province?.value || "",
      city: fields.city?.value || "",
    };
    let data = {
      url: "common_sales_person",
      method: "GET",
      query: `?query=${JSON.stringify(params)}&offset=0&limit=1000`,
    };
    api.call(data, (res) => {
      if (res.status !== 200) {
        status = "error";
      } else {
        if (res.data.data.length === 0) {
          status = "empty";
        }
        setResult(res.data);
        console.log(res.data);
      }
      setStatus(status);
    });
  };

  // functions
  const onSearch = () => {
    if (fields.country === null) {
      common.notify("E", "Please select the country");
    } else {
      getAgentList();
      setStatus("process");
    }
  };

  const onSelect = (id) => {
    setConnectId(connectId === id ? "" : id);
  };

  const onSubmit = () => {
    if (connectId === "") {
      common.notify("E", "Please select the agent");
    } else {
      setLoading(true);
      let data = {
        url: "student_profile",
        method: "PUT",
        body: {
          connectedSalesPerson: connectId,
        },
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", "Sales person has been connected successfully");
          props.onClose();
        } else {
          common.notify("E", "Unable to connect to sales person please try again");
        }
        setLoading(false);
      });
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={true}
        style={common.modalStyle(85)}
        contentLabel=""
        ariaHideApp={false}
      >
        <div className="register-step-header d-flex justify-content-between align-items-center">
          <p className="register-step-title mb-0">Connect to agent</p>
          <div className="cp" onClick={props.onClose}>
            <img src={common.loadImg("close-icn.svg")} alt="" />
          </div>
        </div>
        <div className="con-agnt-modal-body">
          <div className="cta-filter-wrap px-4 py-2 mb-4">
            {/* <div className="row">
              <div className="col-md-4">
                <label className="label-padding">Country</label>
                <div className="signin-form-row mb-3">
                  <FieldSelect
                    api="common_country"
                    query="?all=show"
                    get="name"
                    set="code"
                    value={fields.country}
                    placeholder="Please select the country"
                    render={selectRender.country}
                    clearable={true}
                    findValue={false}
                    multi={false}
                    onSelect={(data) => {
                      setFields((prev) => ({ ...prev, country: data }));
                      setSelectRender((prev) => ({
                        ...prev,
                        province: data === null ? "empty" : new Date(),
                        city: "empty",
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <label className="label-padding">Province</label>
                <div className="signin-form-row mb-3">
                  <FieldSelect
                    api="common_state"
                    query={`/${fields?.country?.value}?&offset=1&limit=1000`}
                    get="name"
                    set="_id"
                    value={fields.province}
                    placeholder="Please select the province"
                    render={selectRender.province}
                    clearable={true}
                    findValue={false}
                    multi={false}
                    onSelect={(data) => {
                      setFields((prev) => ({
                        ...prev,
                        province: data,
                        city: null,
                      }));
                      setSelectRender((prev) => ({
                        ...prev,
                        city: data === null ? "empty" : new Date(),
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <label className="label-padding">City</label>
                <div className="signin-form-row mb-3">
                  <FieldSelect
                    api="common_city"
                    query={`/${fields?.country?.value}/${fields?.province?.value}?&offset=1&limit=1000`}
                    get="name"
                    set="_id"
                    value={fields.city}
                    placeholder="Please select the city"
                    render={selectRender.city}
                    clearable={true}
                    findValue={false}
                    multi={false}
                    onSelect={(data) => {
                      setFields((prev) => ({ ...prev, city: data }));
                      setSelectRender((prev) => ({
                        ...prev
                      }));
                    }}
                  />
                </div>
              </div>
            </div> */}

            {/* <div className="d-flex justify-content-end">
            <button
              className="btn-default"
              disabled={status === "process"}
              onClick={onSearch}
            >
              Search
            </button>
          </div> */}
          </div>

          {status !== "success" ? (
            <StatusBar status={status} message="default" />
          ) : (
            <div className="agent-list-wrap">
              <Slider {...common.ctaSliderSettings()}>
                {result.data.map((i, k) => (
                  <div
                    key={k}
                    className={`agent-info-wrap position-relative text-center et-cp ${
                      connectId === i.EmployeeId ? "selected-agent" : ""
                    } ${
                      props?.userInfo?.salesPerson !== i.EmployeeId
                        ? ""
                        : "agent-active"
                    }`}
                    onClick={() => {
                      if (props?.userInfo?.salesPerson !== i.EmployeeId) {
                        onSelect(i.EmployeeId);
                      }
                    }}
                  >
                    <div className="mb-2">
                      <img
                        // src={
                        //   i?.businessLogo
                        //     ? common.cloudImg(i.businessLogo)
                        //     : common.loadImg("agent-icn.svg")
                        // }
                        src={common.loadImg("agent-icn.svg")}
                        alt=""
                        className="m-auto"
                      />
                      <h3 className="agent-name">{i.EmployeeName}</h3>
                      <p className="agent-location">
                        {i.Country} {i.State ? "|" : ""} {i.State}{i.City ? "," : ""}{" "}
                        {i.City}
                      </p>
                    </div>

                    {/* <div className="agent-desc">
                      <p>{common.trimString(i.description, 80)}</p>
                    </div> */}
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>

        <div className="register-step-footernew agent-connect-footer">
          <button className="btn-default" disabled={loading} onClick={onSubmit}>
            {loading ? "Connecting..." : "Connect"}
          </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ConnectToAgent;
