import { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext, Link } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import ModalConfirm from "elements/modalConfirm";
import { api, common } from "helpers";
import { isMobile } from "react-device-detect";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-modal";
import { Row } from "react-bootstrap";

function List() {
  // props
  const navigate = useNavigate();
  const { search } = useLocation();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [reload, setReload] = useState(new Date());
  const [deleteData, setDeleteData] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentStatus = search.split("?q=currentStatus=");
  const [user, setUser] = useState({});
  const [myModal, setMyModal] = useState(null);
  const [cancel, setCancel] = useState({
    reason: "",
    error: false,
    submit: false,
  });

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    if (authInfo?.role !== "student") {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            setUser(res.data);
          }
        }
      );
    }
  };

  // functions
  const onActionA = (data) => {
    if (data.type === "Edit") {
      navigate(`/application/status/${data.item._id}`);
    } else if (data.type === "View") {
      if (data.module == "coordinatorAgentApplication") {
        navigate(`/agent/promoters/application/info/${data.item._id}`);
      } else {
        navigate(`/application/status/${data.item._id}`);
      }
    }
  };

  const onActionB = (data) => {
    if (data.type === "Cancel") {
      setDeleteData(data.item);
      setMyModal("cancel");
    }

    if (data.type === "View") {
      setDeleteData(data.item);
      if (data.module == "coordinatorAgentApplication") {
        navigate(`/agent/promoters/application/info/${data.item._id}`);
      } else {
        navigate(`/application/preview/order/${data.item._id}`);
      }
    }

    if (data.type === "Payment") {
      navigate(`/application/payment/fees/${data.item._id}`);
    }
  };

  //api
  const DeleteRecord = (item) => {
    setLoading(true);
    let data = {
      url: "application_order",
      method: "DELETE",
      query: `/${item._id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Record has been removed");
        setDeleteData(null);
        setReload(new Date());
      }
    });
    setLoading(false);
  };

   // handler
   const onCancelChange = (e) => {
    const { value } = e.target;
    let error = false;
    if (value === "" && cancel.submit) {
      error = true;
    }
    setCancel((prev) => ({ ...prev, reason: value, error }));
  };

  const onCancelSubmit = (item) => {
    if (cancel.reason === "") {
      setCancel((prev) => ({ ...prev, error: true, submit: true }));
    } else {
      setLoading("cancel");
      let data = {
        url: "application_status",
        method: "PUT",
        query: `/${item._id}`,
        body: {
          status: "cancelled",
          description: cancel.reason,
        },
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", "Application has been cancelled");
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(null);
        setMyModal(null);
        setReload(new Date());
        setCancel({
          reason: "",
          error: false,
          submit: false,
        });
      });
    }
  };


  return (
    <Fragment>
      <div className="title-wrap d-flex justify-content-between align-items-center">
        <h1 className="page-title">Application</h1>
      </div>
      {/* <div className="content-wrap app-list-wrap">
        <h1 className="page-title">My Application</h1>
        <div className="app-tbls-title">
          {isMobile ? <h1 className="page-title">Paid Applications</h1> : null}
        </div>
        <div className="mb-5">
          <AgTable
            module={
              authInfo.role === "agent" ? "agentApplication" : "application"
            }
            query={{ feePaymentStatus: ["paid"] }}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    courseMulti: { key: "courseId", value: [] },
                    applicationSelect: {
                      key: "currentStatus",
                      value: currentStatus.length ? currentStatus[1] : "",
                    },
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionA}
          />
        </div>
        <div className="app-tbls-title">
          {isMobile ? (
            <h1 className="page-title">Unpaid Applications</h1>
          ) : null}
        </div>
        <AgTable
          query={{ feePaymentStatus: ["not_paid"] }}
          module={
            authInfo.role === "agent"
              ? "agentApplicationOrder"
              : "applicationOrder"
          }
          reload={reload}
          filterData={
            authInfo.role === "agent"
              ? {
                  dateA: {
                    key: "startDate",
                    value: "",
                    title: "Created Date From",
                  },
                  dateB: {
                    key: "endDate",
                    value: "",
                    title: "Created Date To",
                  },
                }
              : {}
          }
          onAction={onActionB}
        />
      </div> */}
      {/* {user.isPromotor && (
        <div className="content-wrap app-list-wrap">
          <h1 className="page-title">Agents Application</h1>
          <div className="app-tbls-title">
            {isMobile ? (
              <h1 className="page-title">Paid Applications</h1>
            ) : null}
          </div>
          <div className="mb-5">
            <AgTable
              module={"coordinatorAgentApplication"}
              query={{ feePaymentStatus: ["paid"] }}
              reload={reload}
              filterData={
                authInfo.role === "agent"
                  ? {
                      courseMulti: { key: "courseId", value: [] },
                      applicationSelect: {
                        key: "currentStatus",
                        value: currentStatus.length ? currentStatus[1] : "",
                      },
                      dateA: {
                        key: "startDate",
                        value: "",
                        title: "Created Date From",
                      },
                      dateB: {
                        key: "endDate",
                        value: "",
                        title: "Created Date To",
                      },
                    }
                  : {}
              }
              onAction={onActionA}
            />
          </div>
          <div className="app-tbls-title">
            {isMobile ? (
              <h1 className="page-title">Unpaid Applications</h1>
            ) : null}
          </div>
          <AgTable
            query={{ feePaymentStatus: ["not_paid"] }}
            module={"coordinatorAgentApplication"}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionB}
          />
        </div>
      )} */}
<div className="content-wrap app-list-wrap">
{(authInfo?.role !== "student" && user?.isPromotor || user?.agent?.isPromotor) ?
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>My Applications</Accordion.Header>
          <Accordion.Body>
      
        {/* <h1 className="page-title">My Application</h1> */}
        <div className="app-tbls-title">
          {isMobile ? <h1 className="page-title">Paid Applications</h1> : null}
        </div>
        <div className="mb-5">
          <AgTable
            module={
              authInfo.role === "agent" ? "agentApplication" : "application"
            }
            query={{ feePaymentStatus: ["paid"] }}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    courseMulti: { key: "courseId", value: [] },
                    applicationSelect: {
                      key: "currentStatus",
                      value: currentStatus.length ? currentStatus[1] : "",
                    },
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionA}
          />
        </div>
        <div className="app-tbls-title">
          {isMobile ? (
            <h1 className="page-title">Unpaid Applications</h1>
          ) : null}
        </div>
        <AgTable
          query={{ feePaymentStatus: ["not_paid"] }}
          module={
            authInfo.role === "agent"
              ? "agentApplicationOrder"
              : "applicationOrder"
          }
          reload={reload}
          filterData={
            authInfo.role === "agent"
              ? {
                  dateA: {
                    key: "startDate",
                    value: "",
                    title: "Created Date From",
                  },
                  dateB: {
                    key: "endDate",
                    value: "",
                    title: "Created Date To",
                  },
                }
              : {}
          }
          onAction={onActionB}
        />
      {/* </div> */}
      </Accordion.Body>
        </Accordion.Item>
      {/* {(user?.isPromotor || user?.agent?.isPromotor) && ( */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Agents Applications</Accordion.Header>
          <Accordion.Body>
        <>
        {/* <div className="content-wrap app-list-wrap"> */}
          {/* <h1 className="page-title">Agents Application</h1> */}
          <div className="app-tbls-title">
            {isMobile  ? (
              <h1 className="page-title">Paid Applications</h1>
            ) : null}
          </div>
          <div className="mb-5">
            <AgTable
              module={"coordinatorAgentApplication"}
              query={{ feePaymentStatus: ["paid"] }}
              reload={reload}
              filterData={
                authInfo.role === "agent"
                  ? {
                      courseMulti: { key: "courseId", value: [] },
                      applicationSelect: {
                        key: "currentStatus",
                        value: currentStatus.length ? currentStatus[1] : "",
                      },
                      dateA: {
                        key: "startDate",
                        value: "",
                        title: "Created Date From",
                      },
                      dateB: {
                        key: "endDate",
                        value: "",
                        title: "Created Date To",
                      },
                    }
                  : {}
              }
              onAction={onActionA}
              title={"Paid Applications"}
            />
          </div>
          <div className="app-tbls-title">
            {isMobile ? (
              <h1 className="page-title">Unpaid Applications</h1>
            ) : null}
          </div>
          <AgTable
            query={{ feePaymentStatus: ["not_paid"] }}
            module={"coordinatorAgentApplication"}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionB}
            title={"Unpaid Applications"}
          />
          </>
        
            </Accordion.Body>
         </Accordion.Item>
      {/* )} */}
      </Accordion>
      :
      <>
      <div className="app-tbls-title">
          {isMobile ? <h1 className="page-title">Paid Applications</h1> : null}
        </div>
        <div className="mb-5">
          <AgTable
            module={
              authInfo.role === "agent" ? "agentApplication" : "application"
            }
            query={{ feePaymentStatus: ["paid"] }}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    courseMulti: { key: "courseId", value: [] },
                    applicationSelect: {
                      key: "currentStatus",
                      value: currentStatus.length ? currentStatus[1] : "",
                    },
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionA}
          />
        </div>
        <div className="app-tbls-title">
          {isMobile ? (
            <h1 className="page-title">Unpaid Applications</h1>
          ) : null}
        </div>
        <AgTable
          query={{ feePaymentStatus: ["not_paid"] }}
          module={
            authInfo.role === "agent"
              ? "agentApplicationOrder"
              : "applicationOrder"
          }
          reload={reload}
          filterData={
            authInfo.role === "agent"
              ? {
                  dateA: {
                    key: "startDate",
                    value: "",
                    title: "Created Date From",
                  },
                  dateB: {
                    key: "endDate",
                    value: "",
                    title: "Created Date To",
                  },
                }
              : {}
          }
          onAction={onActionB}
        />
        </>
}
      </div>

      {/* {deleteData !== null && (
        <ModalConfirm
          loading={loading}
          onYes={() => DeleteRecord(deleteData)}
          onNo={() => setDeleteData(null)}
        />
      )} */}

       {/* Modal */}
       <Modal
              isOpen={myModal === "cancel"}
              style={common.modalStyle(60)}
              contentLabel=""
              ariaHideApp={false}
            >
              <div>
                <div className="register-step-header d-flex justify-content-between align-items-center">
                  <p className="register-step-title mb-0">
                    Cancellation Request
                  </p>
                  <div
                    className="cp"
                    onClick={() => {
                      setMyModal(null);
                      setCancel({
                        reason: "",
                        error: false,
                        submit: false,
                      });
                    }}
                  >
                    <img src={common.loadImg("close-icn.svg")} alt="" />
                  </div>
                </div>
                <div className="register-step-content">
                  <Row>
                    <textarea
                      className="txtarea pt-2"
                      rows={4}
                      placeholder="Reason for cancellation?"
                      value={cancel.reason}
                      onChange={onCancelChange}
                    ></textarea>
                    {cancel.error && (
                      <p className="error-txt p-0">
                        Cancellation reason required
                      </p>
                    )}
                  </Row>
                </div>

                <div className="register-step-footernew">
                  <Link to="">
                    <button
                      className="btn-default"
                      onClick={() => onCancelSubmit(deleteData)}
                      disabled={loading === "cancel"}
                    >
                      {loading === "cancel" ? "Procesing..." : "Submit"}
                    </button>
                  </Link>
                </div>
              </div>
            </Modal>
    </Fragment>
  );
}

export default List;
