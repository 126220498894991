import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Select from "react-select";
// import validator from "validator";

// includes
import { api, common, config, types } from "helpers";
// pages
import FileUpload from "elements/fileUpload";
import ModalConfirm from "elements/modalConfirm";
import StatusBar from "elements/statusBar";
// files
import studentFields from "assets/files/studentFields.json";

function StudentForm(props) {
  const workDocRef = useRef(null);

  const scrollToBottom = () => {
    workDocRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, []);

  const navigate = useNavigate();
  // props
  const { studentId, formType, formModule, formModal, formIndex, formAddNew } =
    props;
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const { id, type } = useParams();
  const nav = {
    personal_address: "education_college",
    education_college: "background",
    background: "workExp",
    workExp: "document",
    document: "document",
  };

  // state
  const [init, setInit] = useState(false);
  const [status, setStatus] = useState("process");
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [errorServer, setErrorServer] = useState(null);
  const [docs, setDocs] = useState({});
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(null);
  const [docsModal, setDocsModal] = useState(null);
  const [examError, setExamError] = useState([]);
  const [data, setData] = useState({
    countryList: [],
    countrySelect: [],
  });
  const [selectRender, setSelectRender] = useState({
    country: "yes",
    province: "yes",
    city: "no",
  });

  const [authData] = useState(common.getAuth());
  const [isEuDocs, setIsEuDocs] = useState(false);
  const [isBrpDocs, setIsBrpDocs] = useState(true);
  const EuList = [
    "AD",
    "AL",
    "AT",
    "AX",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GG",
    "GR",
    "HR",
    "HU",
    "IE",
    "IM",
    "IC",
    "IT",
    "JE",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "MK",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SE",
    "SI",
    "SJ",
    "SK",
    "SM",
    "UA",
    "VA",
  ];
  const [isOtherQualy, setIsOtherQualy] = useState(false);
  const [isWorkExp, setIsWorkExp] = useState(false);

  // effect
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (init && submit) {
      let moduleInfo = String(formModule);
      validate(moduleInfo.split("_"));
    }
  }, [fields]);

  // api
  const initData = () => {
    getCountryList();
    if (formType === "application") {
      applicationInfo();
    } else if (formType === "next") {
      navigate();
    } else {
      if (authData.role === "student") {
        studentInfo();
      } else {
        if (studentId) {
          agentStudentInfo();
        } else {
          studentAdd();
        }
      }
    }
  };

  // api
  const studentInfo = () => {
    let data = {
      url: "student_profile",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = reParse(res.data);

        if (Object.keys(result.fields.highLevelOfEducation).length > 0) {
          if (
            result?.fields?.highLevelOfEducation?.value ==
            "Other Qualifications"
          ) {
            // setCollegeValidation(false);
            setIsOtherQualy(true);
            setEducationValidation("other", true);
          }
          if (
            result?.fields?.highLevelOfEducation?.value ==
            "Work Experience Only"
          ) {
            // setCollegeValidation(false);
            setIsWorkExp(true);
            setEducationValidation("work", true);
          }
        }
        // if (result.fields.birth) {
        let isEu =
          Object.keys(result.fields.birth).length > 0 &&
          EuList.includes(result.fields.birth.value);
        if (isEu) {
          setIsEuDocs(true);
          studentFields["personal"]["euDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
        } else {
          setIsEuDocs(false);
          studentFields["personal"]["euDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
        }
        if (result?.fields?.birth?.value == "GB") {
          setIsBrpDocs(false);
          studentFields["personal"]["brpDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
          studentFields["background"]["passportNumber"] = {
            isReq: "",
            view: ["Passport Number", 4, true, "string"],
          };
        } else {
          setIsBrpDocs(true);
          studentFields["personal"]["brpDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
        }
        if (
          typeof result?.fields?.passportDocs === "string" ||
          result?.fields?.passportDocs instanceof String
        ) {
          result.fields.passportDocs = result.fields?.passportDocs
            ? [result?.fields?.passportDocs]
            : [];
        }
        setFields(result.fields);
        setErrors(result.errors);
        setDocs(result.document);
        setStatus("success");
        setInit(true);
      } else {
        setStatus("error");
      }
    });
  };

  const agentStudentInfo = () => {
    let data = {
      url: "agent_student",
      query: `/${studentId}`,
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = reParse(res.data);
        if (Object.keys(result.fields.highLevelOfEducation).length > 0) {
          if (
            result.fields.highLevelOfEducation.value == "Other Qualifications"
          ) {
            // setCollegeValidation(false);
            setIsOtherQualy(true);
            setEducationValidation("other", true);
          }
          if (
            result.fields.highLevelOfEducation.value == "Work Experience Only"
          ) {
            // setCollegeValidation(false);
            setIsWorkExp(true);
            setEducationValidation("work", true);
          }
        }
        // if (result.fields.birth) {
        let isEu =
          Object.keys(result.fields.birth).length > 0 &&
          EuList.includes(result.fields.birth.value);
        // if (isEu) {
        //   setIsEuDocs(true);
        //   studentFields["personal"]["euDocs"] = {
        //     isReq: "please upload your EU",
        //     view: ["Document", 4, false, "array"],
        //   };
        // } else {
        //   setIsEuDocs(false);
        //   studentFields["personal"]["euDocs"] = {
        //     isReq: "",
        //     view: ["Document", 4, false, "array"],
        //   };
        // }
        if (result?.fields?.birth?.value == "GB") {
          setIsBrpDocs(false);
          studentFields["personal"]["brpDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
        } else {
          setIsBrpDocs(true);
          studentFields["personal"]["brpDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
        }

        setFields(result.fields);
        setErrors(result.errors);
        setDocs(result.document);
        setStatus("success");
        setInit(true);
      } else {
        setStatus("error");
      }
    });
  };

  const applicationInfo = () => {
    let data = {
      url: "application_profile",
      query: `/${studentId}`,
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = reParse(res.data);
        if (Object.keys(result.fields.highLevelOfEducation).length > 0) {
          if (
            result.fields.highLevelOfEducation.value == "Other Qualifications"
          ) {
            // setCollegeValidation(false);
            setIsOtherQualy(true);
            setEducationValidation("other", true);
          }
          if (
            result.fields.highLevelOfEducation.value == "Work Experience Only"
          ) {
            // setCollegeValidation(false);
            setIsWorkExp(true);
            setEducationValidation("work", true);
          }
        }
        // if (result.fields.birth) {
        let isEu =
          Object.keys(result.fields.birth).length > 0 &&
          EuList.includes(result.fields.birth.value);
        if (isEu) {
          setIsEuDocs(true);
          studentFields["personal"]["euDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
        } else {
          setIsEuDocs(false);
          studentFields["personal"]["euDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
        }
        if (result?.fields?.birth?.value == "GB") {
          setIsBrpDocs(false);
          studentFields["personal"]["brpDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
          studentFields["background"]["passportNumber"] = {
            isReq: "",
            view: ["Passport Number", 4, true, "string"],
          };
        } else {
          setIsBrpDocs(true);
          studentFields["personal"]["brpDocs"] = {
            isReq: "",
            view: ["Document", 4, false, "array"],
          };
        }
        setFields(result.fields);
        setErrors(result.errors);
        setDocs(result.document);
        setStatus("success");
        setInit(true);
      } else {
        setStatus("error");
      }
    });
  };

  const getCountryList = () => {
    let data = {
      url: "common_country",
      query: "?all=show",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status == 200) {
        let result =
          res?.data?.data?.length > 0
            ? res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
            : res.data.data;
        setData((prev) => ({
          ...prev,
          countryList: result,
          countrySelect: common.SetMultiSelect(result, "name", "code"),
          nationality: common.SetMultiSelect(result, "nationality", "code"),
        }));
      }
    });
  };

  // handler
  const onInputChange = (e, sub, k) => {
    const { name, value } = e.target;
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] = value;
      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const onNumberInputChange = (e, sub, k) => {
    const { name, value } = e.target;
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] = common.onlyNumbers(value);
      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: common.onlyNumbers(value),
      }));
    }
  };

  const onInputChangeScore = (e, k) => {
    const { name, value } = e.target;
    if (
      ["IELTS", "TOEFL", "IELTS UKVI"].includes(
        fields?.exam[k]?.examType?.value
      )
    ) {
      let process = true;
      if (fields?.exam[k]?.examType?.value === "IELTS" && Number(value) > 9) {
        process = false;
      } else if (
        fields?.exam[k]?.examType?.value === "TOEFL" &&
        Number(value) > 30
      ) {
        process = false;
      } else if (
        fields?.exam[k]?.examType?.value === "IELTS UKVI" &&
        Number(value) > 9
      ) {
        process = false;
      }
      //setExamError
      if (process) {
        let f = common.reParse(fields);
        let val = common.onlyFloat(value);
        f.exam[k][name] = val;
        if (fields?.exam[k]?.examType?.value === "TOEFL") {
          f.exam[k].testScore =
            Number(f.exam[k].listening) +
            Number(f.exam[k].reading) +
            Number(f.exam[k].writing) +
            Number(f.exam[k].speaking);
          setFields(f);
        } else {
          let total =
            (Number(f.exam[k].listening) +
              Number(f.exam[k].reading) +
              Number(f.exam[k].writing) +
              Number(f.exam[k].speaking)) /
            4;

          f.exam[k].testScore = Math.round(total * 2) / 2;
          setFields(f);
        }
      }
    } else {
    }
  };

  const onDatePickerChange = (name, value, sub, k) => {
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] =
        value === "" ? "" : moment(value).format(config.elements.dp.A);
      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: value === "" ? "" : moment(value).format(config.elements.dp.A),
      }));
    }
  };

  const setCollegeValidation = (isreq) => {
    studentFields["college"] = {
      institutionCountry: {
        isReq: isreq ? "Select the country" : "",
        view: ["Country of Education", 4, false, "select"],
      },
      institutionName: {
        isReq: isreq ? "Institution name is required" : "",
        view: ["Name of Institution", 4, false, "string"],
      },
      qualification: {
        isReq: isreq ? "Qualification is required" : "",
        view: ["Qualification", 4, true, "string"],
      },
      degree: {
        isReq: isreq ? "Degree Name is required" : "",
        view: ["Degree Name", 4, true, "string"],
      },
      startDate: {
        isReq: "",
        view: ["Start Date", 4, true, "date"],
      },
      endDate: {
        isReq: "",
        view: ["End Date", 4, true, "date"],
      },
      graduationDate: {
        isReq: "",
        view: ["Graduation Date", 4, true, "date"],
      },
      gradingSystem: {
        isReq: "",
        view: ["Grading System", 4, true, "string"],
      },
      score: {
        isReq: "",
        view: ["Score", 4, true, "string"],
      },
      docs: {
        isReq: "",
        view: ["Document", 4, false, "array"],
      },
    };
  };

  const setEducationValidation = (type, isTrue) => {
    if (type == "other") {
      studentFields["education"]["otherQualification"] = {
        isReq: isTrue ? "Qualification is Required" : "",
        view: ["Qualification", 4, true, "string"],
      };
      studentFields["education"]["otherQualificationDesc"] = {
        isReq: isTrue ? "Description is Required" : "",
        view: ["Description", 4, true, "string"],
      };
      studentFields["education"]["otherQualificationDocs"] = {
        isReq: isTrue ? "please upload the docs" : "",
        view: ["Document", 4, false, "array"],
      };
    } else {
      studentFields["education"]["yearOfExp"] = {
        isReq: isTrue ? "please select year of experience" : "",
        view: ["Year of Working", 4, false, "select"],
      };
      studentFields["education"]["ruWorking"] = {
        isReq: isTrue ? "please select yes or no" : "",
        view: ["Are you currently working?", 4, true, "string"],
      };
      studentFields["education"]["expDocs"] = {
        isReq: isTrue ? "please upload the docs" : "",
        view: ["Document", 4, true, "array"],
      };
    }
  };

  const onSelectChange = (name, value, sub, k) => {
    if (name == "highLevelOfEducation") {
      // if (
      //   value &&
      //   (value.value == "Other Qualifications" ||
      //     value.value == "Work Experience Only")
      // ) {
      //   setCollegeValidation(false);
      // } else {
      //   setCollegeValidation(true);
      // }
      if (value && value.value == "Other Qualifications") {
        setIsOtherQualy(true);
        setEducationValidation("other", true);
      } else {
        setEducationValidation("other", false);
        setIsOtherQualy(false);
      }

      if (value && value.value == "Work Experience Only") {
        setIsWorkExp(true);
        setEducationValidation("work", true);
      } else {
        setIsWorkExp(false);
        setEducationValidation("work", false);
      }
    }

    let isEu = value && EuList.includes(value.value);
    if (isEu) {
      setIsEuDocs(true);
      studentFields["personal"]["euDocs"] = {
        isReq: "",
        view: ["Document", 4, false, "array"],
      };
    } else {
      setIsEuDocs(false);
      studentFields["personal"]["euDocs"] = {
        isReq: "",
        view: ["Document", 4, false, "array"],
      };
    }

    if (name == "birth" && value?.value == "GB") {
      setIsBrpDocs(false);
      studentFields["personal"]["brpDocs"] = {
        isReq: "",
        view: ["Document", 4, false, "array"],
      };
      studentFields["background"]["passportNumber"] = {
        isReq: "",
        view: ["Passport Number", 4, true, "string"],
      };
      setFields((prev) => ({
        ...prev,
        passportNumber: "",
        passportDocs: [],
        brpDocs: "",
        euDocs: "",
      }));
    } else if (name == "birth" && value !== null && value?.value != "GB") {
      setIsBrpDocs(true);
      studentFields["personal"]["brpDocs"] = {
        isReq: "",
        view: ["Document", 4, false, "array"],
      };
      studentFields["background"]["passportNumber"] = {
        isReq: "Passport Number is required",
        view: ["Passport Number", 4, true, "string"],
      };
      setFields((prev) => ({
        ...prev,
        passportDocs: [],
      }));
    }
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] = value;
      if (sub === "exam") {
        f.exam[k].testScore = "";
        ["listening", "reading", "writing", "speaking"].map(
          (item) => (f.exam[k][item] = "")
        );
      }
      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const uploadFile = async (arr) => {
    var formdata = new FormData();
    arr.map((i) => {
      formdata.append("file", i);
    });
    formdata.append("type", "student");

    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  // submit hander

  const onSubmit = async () => {
    setSubmit(true);
    let currentModule = String(formModule).split("_");

    let submit = validate(currentModule);
    if (submit) {
      setSubmit(false);
      setLoading(true);
      let error = "";
      let params = getFields(currentModule);

      // file upload
      if (
        [
          "college",
          "education_college",
          "exam",
          "exam_background",
          "workExp",
        ].includes(formModule)
      ) {
        let docModule = String(formModule);

        if (docModule === "education_college") {
          docModule = "college";
        } else if (docModule === "exam_background") {
          docModule = "exam";
        }
        await Promise.all(
          docs[docModule].map(async (i, k) => {
            params[docModule][k].docs = params[docModule][k]?.docs?.filter(
              (item) => item.match(/live|dev|homeneu/)
            );
            if (i.length > 0) {
              let result = await uploadFile(i);
              if (result.status !== 200) {
                error = result.data.error;
              } else {
                params[docModule][k].docs = [
                  ...params[docModule][k].docs,
                  ...result.data.link,
                ];
              }
            }
          })
        );
      }

      if (["background"].includes(currentModule[0])) {
        if (docs["passportDocs"] && docs["passportDocs"].length) {
          let result = await uploadFile(docs["passportDocs"]);
          if (result.status !== 200) {
            error = result.data.error;
          } else {
            let getPrvFile = params["passportDocs"].filter((item) =>
              item.match(/live|dev|homeneu/)
            );
            params["passportDocs"] = [...getPrvFile, ...result.data.link];
            //params["passportDocs"] = result.data.link;
          }
        }
      }

      if (["personal"].includes(currentModule[0])) {
        // if (docs["passportDocs"] && docs["passportDocs"].length) {
        //   let result = await uploadFile(docs["passportDocs"]);
        //   if (result.status !== 200) {
        //     error = result.data.error;
        //   } else {
        //     let getPrvFile = params["passportDocs"].filter((item) =>
        //       item.match(/live|dev|homeneu/)
        //     );
        //     params["passportDocs"] = [...getPrvFile, ...result.data.link];
        //     //params["passportDocs"] = result.data.link;
        //   }
        // }

        if (docs["euDocs"] && docs["euDocs"].length) {
          let result = await uploadFile(docs["euDocs"]);
          if (result.status !== 200) {
            error = result.data.error;
          } else {
            params["euDocs"] = result.data.link[0];
          }
        }

        if (docs["brpDocs"] && docs["brpDocs"].length) {
          let result = await uploadFile(docs["brpDocs"]);
          if (result.status !== 200) {
            error = result.data.error;
          } else {
            params["brpDocs"] = result.data.link[0];
          }
        }
      }

      if (["education"].includes(currentModule[0])) {
        if (
          docs["otherQualificationDocs"] &&
          docs["otherQualificationDocs"].length
        ) {
          let result = await uploadFile(docs["otherQualificationDocs"]);
          if (result.status !== 200) {
            error = result.data.error;
          } else {
            let getPrvFile = params["otherQualificationDocs"].filter((item) =>
              item.match(/live|dev|homeneu/)
            );
            params["otherQualificationDocs"] = [
              ...getPrvFile,
              ...result.data.link,
            ];
          }
        }

        if (docs["expDocs"] && docs["expDocs"].length) {
          let result = await uploadFile(docs["expDocs"]);
          if (result.status !== 200) {
            error = result.data.error;
          } else {
            let getPrvFile = params["expDocs"].filter((item) =>
              item.match(/live|dev|homeneu/)
            );
            params["expDocs"] = [...getPrvFile, ...result.data.link];
          }
        }
      }
      if (error) {
        common.notify("E", error);
        setLoading(false);
      } else {
        let data = {};
        if (formType === "application") {
          data = {
            url: "application_profile",
            method: "PUT",
            query: `/${studentId}`,
            body: params,
          };
        } else {
          if (authData.role === "student") {
            data = {
              url: "student_profile",
              method: "PUT",
              body: params,
            };
          } else if (
            authData.role === "agent" ||
            authData.role === "sub_agent"
          ) {
            if (studentId === "") {
              data = {
                url: "agent_student",
                method: "POST",
                body: params,
              };
            } else {
              data = {
                url: "agent_student",
                method: "PUT",
                query: `/${studentId}`,
                body: params,
              };
            }
          }
        }
        // return
        api.call(data, (res) => {
          if (res.status === 200) {
            if (formType === "application") {
              props?.onSuccess(res.data._id);
              navigate(`/application/status/${id}`);
            } else if (formType === "agentNext") {
              if (studentId) {
                agentStudentInfo();
              }

              navigate(`/agent/student/edit/${id}/${nav[type]}/2`);
            } else {
              props?.onSuccess(res.data._id);
              if (authData.role === "student") {
                outletAction("myProgress");
              }
            }
          } else if (res.status === 500) {
            common.notify("E", res.data.error);
            // setErrorServer({ email: res.data.error });
          } else {
            common.notify("E", res.data.error);
          }
          setLoading(false);
        });
      }
    }
  };

  // validation
  const validate = (type) => {
    let isValid = true;
    let err = common.reParse(errors);
    type.map((tItem) => {
      if (["college", "exam", "workExp"].includes(tItem)) {
        fields[tItem].map((item, key) => {
          for (var cKey of Object.keys(studentFields[tItem])) {
            err[tItem][key][cKey] = "";
            if (
              studentFields[tItem][cKey].isReq != "" &&
              (item[cKey] == "" || item[cKey] == null)
            ) {
              err[tItem][key][cKey] = studentFields[tItem][cKey].isReq;
              isValid = false;
            }
          }
        });
      } else {
        for (var key of Object.keys(studentFields[tItem])) {
          err[key] = "";
          if (
            studentFields[tItem][key].isReq != "" &&
            (fields[key] == "" || fields[key] == null)
          ) {
            let byPassError = false;
            if (
              [
                "passportNumber",
                "grade",
                "countryOfEducation",
                "financeYearTakenBefore",
              ].includes(key)
            ) {
              if (key === "passportNumber" && fields?.birth?.value == "GB") {
                err.passportNumber = "";
                byPassError = true;
              }
              if (
                (["Other Qualifications", "Work Experience Only"].includes(
                  fields?.highLevelOfEducation?.value
                ) &&
                  key === "grade") ||
                key === "countryOfEducation"
              ) {
                err.grade = "";
                err.countryOfEducation = "";
                byPassError = true;
              }
              if (
                key === "financeYearTakenBefore" &&
                fields?.isFinanceTakenBefore === "N"
              ) {
                err.financeYearTakenBefore = "";
                byPassError = true;
              }
            }

            if (!byPassError) {
              err[key] = studentFields[tItem][key].isReq;
              isValid = false;
            }
          } else if (
            key == "email" &&
            studentFields[tItem][key].isEmail != "" &&
            fields[key]
          ) {
            if (!common.isEmail(fields[key])) {
              isValid = false;
              err[key] = studentFields[tItem][key].isEmail;
            }
          }
        }
      }

      // passport validation
      // if (tItem === "personal") {
      //   if (
      //     types.passportCountry.includes(fields.birth?.value) &&
      //     fields.passportNumber !== ""
      //   ) {
      //     if (
      //       !validator.isPassportNumber(
      //         fields.passportNumber,
      //         fields.birth.value
      //       )
      //     ) {
      //       err.passportNumber = "Invalid passport number";
      //       isValid = false;
      //     }
      //   }
      // }
    });

    setErrors(err);
    return isValid;
  };

  // support
  const studentAdd = () => {
    let result = reParse({});
    if (result?.fields?.birth?.value === "GB") {
      setIsBrpDocs(false);
    }
    setFields(result.fields);
    setErrors(result.errors);
    setDocs(result.document);
    setStatus("success");
    setInit(true);
  };

  const reParse = (res) => {
    let resData = common.reParse(res);
    let formFields = common.reParse(studentFields);
    let document = {
      college: [],
      exam: [],
      workExp: [],
      otherQualificationDocs: [],
      expDocs: [],
      passportDocs: [],
    };

    let result = {
      // personal
      firstName: resData.firstName ?? "",
      lastName: resData.lastName ?? "",
      dob: common.dateFormat(resData.dob, "A"),
      gender: common.FindSelect(types.gender, resData.gender),
      nationality: resData?.nationality
        ? {
            label: resData.nationality?.nationality,
            value: resData.nationality?._id,
          }
        : {
            label: "India",
            value: "IN",
          },
      birth: resData?.birth
        ? {
            label: resData?.birth?.name,
            value: resData?.birth?._id,
          }
        : {
            label: "India",
            value: "IN",
          },
      firstLanguage: resData?.firstLanguage ?? "",
      maritalStatus: resData?.maritalStatus
        ? common.FindSelect(types.marital, resData?.maritalStatus)
        : null,
      passportNumber: resData?.passportNumber ?? "",
      // address
      addressWithRegion: resData?.addressWithRegion ?? "",
      address2: resData?.address2 ?? "",
      country: resData?.country
        ? {
            label: resData?.country?.name ?? "India",
            value: resData?.country?._id ?? "IN",
          }
        : {
            label: "India",
            value: "IN",
          },
      province: resData?.province ?? "",
      city: resData?.city ?? "",

      // province: resData?.province
      //   ? {
      //       label: resData?.province,
      //       value: resData?.province,
      //     }
      //   : "",
      // city: resData?.city
      //   ? {
      //       label: resData?.city,
      //       value: resData?.city,
      //     }
      //   : "",
      postalCode: resData?.postalCode ?? "",
      email: resData?.email ?? "",
      mobile: resData?.mobile ?? "",
      mobileCode: resData?.mobileCode ?? "+44",
      // education
      countryOfEducation: resData?.countryOfEducation
        ? {
            label: resData?.countryOfEducation?.name,
            value: resData?.countryOfEducation?._id,
          }
        : "",
      highLevelOfEducation: resData?.highLevelOfEducation
        ? common.FindSelect(
            types.educationLevelProfile,
            resData?.highLevelOfEducation
          )
        : "",
      grade: resData?.grade ?? "",
      // college
      college: resData?.college ?? [],
      // exam
      exam: resData?.exam ?? [],
      // background
      financeYearTakenBefore: resData?.financeYearTakenBefore
        ? common.FindSelect(
            types.financeYearTakenBefore,
            resData?.financeYearTakenBefore
          )
        : "",
      visaStatus: resData?.visaStatus
        ? common.FindSelect(types.visaStatus, resData?.visaStatus)
        : "",
      livingPeriod: resData?.livingPeriod
        ? common.FindSelect(types.livingPeriod, resData?.livingPeriod)
        : "",
      isFinanceTakenBefore: resData?.isFinanceTakenBefore ?? "N",
      refugee: resData?.refugee ?? "N",
      publicCare: resData?.publicCare ?? "N",
      disability: resData?.disability ?? "N",
      // work
      workExp: resData?.workExp ?? [],
      // document
      passportDocs: resData?.passportDocs || [],
      visaDocs: resData.visaDocs ?? "",
      otherActivity: [],
      extraCurricular: [],
      brpDocs: resData?.brpDocs ?? "",
      euDocs: resData?.euDocs ?? "",
      otherQualification: resData?.otherQualification ?? "",
      otherQualificationDesc: resData?.otherQualificationDesc ?? "",
      otherQualificationDocs: resData?.otherQualificationDocs ?? "",
      ruWorking: resData?.ruWorking ?? "",
      yearOfExp: resData?.yearOfExp
        ? common.FindSelect(types.yearOfExp, resData?.yearOfExp)
        : "",
      expDocs: resData?.expDocs ?? "",
    };

    // default array
    for (var key of Object.keys(formFields)) {
      if (types.studentArrayFields.includes(key)) {
        Object.keys(formFields[key]).forEach((i) => {
          if (i === "docs") {
            formFields[key][i] = [];
          } else {
            formFields[key][i] = "";
          }
        });
      }
    }

    // Array: college
    if (!result.college.length) {
      document.college.push([]);
      result.college = [formFields.college];
    } else {
      result.college.map((i, k) => {
        document.college.push([]);
        i.institutionCountry = i.institutionCountry
          ? {
              label: i.institutionCountry?.name,
              value: i.institutionCountry?._id,
            }
          : null;
        i.startDate = common.dateFormat(i.startDate, "A");
        i.endDate = common.dateFormat(i.endDate, "A");
        i.graduationDate = common.dateFormat(i.graduationDate, "A");
        i.gradingSystem = common.FindSelect(
          types.gradingSystem,
          i.gradingSystem
        );
      });
      // edit
      if (formIndex === "new") {
        document.college.push([]);
        result.college.push(formFields.college);
      }
    }

    // Array: Exam
    if (!result.exam.length) {
      document.exam.push([]);
      result.exam = [formFields.exam];
    } else {
      result.exam.map((i, k) => {
        document.exam.push([]);
        i.examType = common.FindSelect(types.test, i.examType);
        i.dateOfIssue = common.dateFormat(i.dateOfIssue, "A");
      });
      // edit
      if (formIndex === "new") {
        document.exam.push([]);
        result.exam.push(formFields.exam);
      }
    }

    // Array: WorkExp
    if (!result.workExp.length) {
      document.workExp.push([]);
      result.workExp = [formFields.workExp];
    } else {
      result.workExp.map((i, k) => {
        document.workExp.push([]);
        i.joinDate = common.dateFormat(i.joinDate, "A");
        i.endDate = common.dateFormat(i.endDate, "A");
      });
      // edit
      if (formIndex === "new") {
        document.workExp.push([]);
        result.workExp.push(formFields.workExp);
      }
    }

    if (!resData.otherActivity) {
      resData.otherActivity = [formFields.otherActivity];
    }
    if (!resData.extraCurricular) {
      resData.extraCurricular = [formFields.extraCurricular];
    }

    // set error
    let error = {};
    let errorFields = common.reParse(formFields);

    for (var key of Object.keys(errorFields)) {
      if (types.studentArrayFields.includes(key)) {
        Object.keys(errorFields[key]).forEach((i) => {
          errorFields[key][i] = "";
        });
        error[key] = [];
        result[key].map(() => {
          error[key].push(errorFields[key]);
        });
      } else {
        Object.keys(errorFields[key]).forEach((i) => {
          error[i] = "";
        });
      }
    }
    //document.passportDocs = resData?.passportDocs ?? [];

    return { fields: result, errors: error, document };
  };

  const getFields = (type) => {
    let newFields = common.reParse(fields);
    let result = {};
    type.map((tItem) => {
      if (types.studentArrayFields.includes(tItem)) {
        result[tItem] = [];
        newFields[tItem].map((i) => {
          for (var key of Object.keys(i)) {
            if (!["docs"].includes(key)) {
              i[key] = i[key]?.value ?? i[key];
            }
          }
          result[tItem].push(i);
        });
      } else {
        for (var key of Object.keys(studentFields[tItem])) {
          result[key] = newFields[key]?.value ?? newFields[key];
        }
      }
    });
    return result;
  };

  const addNew = (type) => {
    let formFields = common.reParse(studentFields);
    // document
    setDocs((prev) => {
      let nData = { ...prev };
      nData[type].push([]);
      return nData;
    });

    // fields
    setFields((prev) => {
      let nData = { ...prev };
      if (["college", "exam", "workExp"].includes(type)) {
        formFields[type].docs = [];
      }
      nData[type].push(formFields[type]);
      return nData;
    });
    // errors
    let e = common.reParse(errors);
    Object.keys(formFields[type]).forEach((i) => (formFields[type][i] = ""));
    e[type].push(formFields[type]);
    setErrors(e);
  };

  const removeNew = (type, i) => {
    let doc = { ...docs };
    doc[type].splice(i, 1);
    setDocs(doc);

    let fld = { ...fields };

    fld[type].splice(i, 1);
    setFields(fld);
  };

  const addDocs = (type, value, multiple = true, k, direct = false) => {
    scrollToBottom();

    if (direct && multiple) {
      setDocs((prev) => {
        let nData = { ...prev };
        nData[type].push(...value.acceptedFiles);
        return nData;
      });
      setFields((prev) => {
        let f = prev;
        f[type] = [
          ...f[type],
          ...value.acceptedFiles?.map((item) => item?.name),
        ];
        return f;
      });
    } else if (multiple) {
      setDocs((prev) => {
        let nData = { ...prev };
        nData[type][value.id].push(...value.acceptedFiles);
        return nData;
      });
      setFields((prev) => {
        let f = prev;
        f[type][k].docs = [...f[type][k]?.docs, value?.acceptedFiles[0]?.name];
        return f;
      });
    } else {
      setFields((prev) => ({ ...prev, [type]: value.acceptedFiles }));
      setDocs((prev) => ({ ...prev, [type]: value.acceptedFiles }));
    }
  };

  const onYes = () => {
    //setDocsModal

    if (confirm[0] === "docs") {
      setFields((prev) => {
        let nData = { ...prev };
        nData[confirm[1]][confirm[2]].docs.splice(confirm[3], 1);
        return nData;
      });
      setDocs((prev) => {
        let nData = { ...prev };
        nData[confirm[1]][confirm[2]]?.splice(confirm[3], 1);
        return nData;
      });
    } else if (confirm[0] === "fields") {
      setDocs((prev) => {
        let nData = { ...prev };
        nData[confirm[1]][confirm[2]]?.splice(confirm[3], 1);
        return nData;
      });
    } else if (confirm[0] === "dFields") {
      setDocs((prev) => {
        let nData = { ...prev };
        nData[confirm[1]]?.splice(confirm[2], 1);
        return nData;
      });
    } else if (confirm[0] === "dDocs") {
      setFields((prev) => {
        let nData = { ...prev };
        nData[confirm[1]].splice(confirm[2], 1);
        return nData;
      });
    } else if (confirm[0] === "docs-single") {
      setDocs((prev) => ({ ...prev, [confirm[1]]: [] }));
    } else if (confirm[0] === "docs-single-fields") {
      setFields((prev) => ({ ...prev, [confirm[1]]: "" }));
    }
    setConfirm(null);
  };

  // elements
  const InputElement = (
    name,
    label,
    size = 6,
    readOnly = false,
    sub = null,
    key = null
  ) => {
    return (
      <Col md={size}>
        <label className="form-label">{label}</label>
        <div className="signin-form-row">
          <input
            type="text"
            name={name}
            className="form-control"
            value={sub ? fields[sub][key][name] : fields?.[name]}
            onChange={(e) => onInputChange(e, sub, key)}
            readOnly={readOnly}
            placeholder={`Please enter the ${label}`}
          />
        </div>
        <p className="error-txt">
          {errorServer?.[name] ??
            (sub ? errors?.[sub]?.[key]?.[name] : errors?.[name])}
        </p>
      </Col>
    );
  };

  const InputAreaElement = (
    name,
    label,
    size = 6,
    readOnly = false,
    sub = null,
    key = null
  ) => {
    return (
      <Col md={size}>
        <label className="form-label">{label}</label>
        <div className="signin-form-row">
          <textarea
            rows={5}
            name={name}
            className="form-control"
            value={sub ? fields[sub][key][name] : fields?.[name]}
            onChange={(e) => onInputChange(e, sub, key)}
            placeholder={`Please enter the ${label}`}
            readOnly={readOnly}
          />
        </div>
        <p className="error-txt">
          {errorServer?.[name] ??
            (sub ? errors?.[sub]?.[key]?.[name] : errors?.[name])}
        </p>
      </Col>
    );
  };

  const DatePickerElement = (name, label, size = 6, sub = null, key = null) => {
    let set = true;
    return (
      <Col md={size}>
        <label className="form-label">{label}</label>
        <div className="signin-form-row date-picker">
          <DateRangePicker
            initialSettings={{
              ...config.elements.singleDp,
              ...(name == "dob"
                ? {
                    startDate: "1997-05-05",
                    minYear: parseInt(
                      moment().subtract(70, "years").format("Y")
                    ),
                    maxYear: parseInt(
                      moment().subtract(15, "years").format("Y")
                    ),
                  }
                : {}),
            }}
            // onShow={(event, picker) => {
            //   onDatePickerChange(name, picker.startDate, sub, key);
            // }}
            onCallback={(end) => {
              onDatePickerChange(name, end, sub, key);
            }}
          >
            <input
              type="text"
              className="form-control date-picker"
              value={sub ? fields[sub][key][name] : fields?.[name]}
              placeholder={`Please select the ${label}`}
              readOnly={true}
            />
          </DateRangePicker>
          <button
            className="clear-icn"
            onClick={() => onDatePickerChange(name, "", sub, key)}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </button>
          <p className="error-txt">
            {sub ? errors?.[sub]?.[key]?.[name] : errors?.[name]}
          </p>
        </div>
      </Col>
    );
  };

  const SelectElement = (
    type,
    name,
    label,
    size = 6,
    sub = null,
    key = null
  ) => {
    return (
      <Col md={size}>
        <label className="form-label">{label}</label>
        <div className="signin-form-row">
          <Select
            value={sub ? fields[sub][key][name] : fields?.[name]}
            options={types[type]}
            className="custom-select-box"
            isClearable={true}
            onChange={(data) => onSelectChange(name, data, sub, key)}
          />
        </div>
        <p className="error-txt">
          {sub ? errors?.[sub]?.[key]?.[name] : errors?.[name]}
        </p>
      </Col>
    );
  };

  const SelectServerElement = (
    type,
    name,
    label,
    size = 6,
    sub = null,
    key = null
  ) => {
    return (
      <Col md={size}>
        <label className="form-label">{label}</label>
        <div className="signin-form-row">
          <Select
            value={sub ? fields[sub][key][name] : fields?.[name]}
            options={data[type]}
            className="custom-select-box"
            isClearable={true}
            onChange={(data) => onSelectChange(name, data, sub, key)}
          />
        </div>
        <p className="error-txt">
          {sub ? errors?.[sub]?.[key]?.[name] : errors?.[name]}
        </p>
      </Col>
    );
  };
  const MobileInputElement = (name1, name2, label, size = 6) => {
    return (
      <Col md={size}>
        <label className="form-label">{label}</label>
        <div className="row">
          <div className="col-md-12 col-sm-10 signin-form-row">
            {/* <input
              type="text"
              name={name2}
              className="form-control"
              value={fields?.[name2]}
              onChange={(e) => onNumberInputChange(e, null, null)}
              maxLength={17}
            /> */}
            <PhoneInput
              inputProps={{
                name: "mobile",
                // required: true,
                // autoFocus: true,
              }}
              value={fields?.[name2]}
              country={"gb"}
              onChange={(phone) =>
                onNumberInputChange({ target: { name: name2, value: phone } })
              }
            />
          </div>
        </div>
        <p className="error-txt">{errors[name2]}</p>
      </Col>
    );
  };

  // render
  return (
    <Fragment>
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          {["personal", "address", "personal_address"].includes(formModule) && (
            <Fragment>
              <div className={!formModal ? "" : ""}>
                {!formModal && (
                  <div className="register-step-header-general">
                    <p className="register-step-title mb-0">
                      General Information
                    </p>
                  </div>
                )}
                {["personal", "personal_address"].includes(formModule) && (
                  <Fragment>
                    <div className="register-step-header d-flex justify-content-between align-items-center">
                      <p className="register-step-title mb-0">Personal</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="register-step-content">
                      <Row>
                        {InputElement("firstName", "First Name")}
                        {InputElement("lastName", "Last Name")}
                        {DatePickerElement("dob", "Date Of Birth")}
                        {SelectElement("gender", "gender", "Gender")}
                        {SelectElement(
                          "marital",
                          "maritalStatus",
                          "Marital Status"
                        )}
                        {InputElement("firstLanguage", "First Language")}
                        {SelectServerElement(
                          "nationality",
                          "nationality",
                          "Nationality"
                        )}

                        {SelectServerElement(
                          "countrySelect",
                          "birth",
                          "Country of Birth"
                        )}
                        {/* {(fields?.birth?.value !== "GB" ||
                          fields?.birth == "") &&
                          InputElement("passportNumber", "Passport Number")} */}

                        {/* <FileUpload
                          label="Passport Docs"
                         
                          onSuccess={(files) => {
                            setDocsModal((prev) => {
                              let nData = { ...prev };
                              if (
                                docsModal?.edit === null ||
                                docsModal?.edit?.[1] === null
                              ) {
                                nData.docs.push(...files.acceptedFiles);
                              } else {
                                nData.docs = [...files.acceptedFiles];
                              }
                              return nData;
                            })
                            
                          }}
                        />

                        {docsModal?.docs.map((item, key) => (
                          <div
                            className="d-flex align-items-center justify-content-between pt-3"
                            key={key}
                          >
                            <p className="agt-proof-file mb-0">{item.name}</p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={() => setConfirm(["modal", key])}
                            />
                          </div>
                        ))} */}
                        {/* <FileUpload
                          multiple={true}
                          label={
                            fields.birth && fields.birth.value == "GB"
                              ? "Birth Certificate or Proof of ID Document"
                              : "Passport Document"
                          }
                          onSuccess={(files) =>
                            addDocs("passportDocs", files, true, 0, true)
                          }
                        /> */}
                        {/* already added */}
                        {/* {fields?.passportDocs.length >
                          docs?.passportDocs.length ? (
                          <>
                            {typeof fields?.passportDocs !== "string" ? (
                              fields?.passportDocs.map((item, key) => {
                                return item.match(/live|dev|homeneu/) ? (
                                  <div className="d-flex align-items-center justify-content-between pt-3">
                                    <p
                                      className="agt-proof-file mb-0 et-link"
                                      onClick={() =>
                                        item.match(/live|dev|homeneu/)
                                          ? common.openCloudUrl(item)
                                          : null
                                      }
                                    >
                                      {item.match(/live|dev|homeneu/)
                                        ? common.fileName(item)
                                        : item}
                                    </p>
                                    <img
                                      src={common.loadImg(
                                        "iconly-dark-delete.svg"
                                      )}
                                      className="et-cp"
                                      onClick={() =>
                                        setConfirm([
                                          "dDocs",
                                          "passportDocs",
                                          key,
                                        ])
                                      }
                                    />
                                  </div>
                                ) : null;
                              })
                            ) : (
                              <div className="d-flex align-items-center justify-content-between pt-3">
                                <p
                                  className="agt-proof-file mb-0 et-link"
                                  onClick={() =>
                                    common.openCloudUrl(fields.passportDocs)
                                  }
                                >
                                  {common.fileName(fields.passportDocs)}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() => {
                                    setConfirm([
                                      "docs-single-fields",
                                      "passportDocs",
                                      0,
                                      0,
                                    ]);
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ) : null} */}

                        {/* newly added */}
                        {/* {docs?.passportDocs?.map((item, key) => (
                          <div
                            className="d-flex align-items-center justify-content-between pt-3"
                            key={key}
                          >
                            <p className="agt-proof-file mb-0">{item.name}</p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={
                                () =>
                                  setConfirm([
                                    "dFields",
                                    "passportDocs",
                                    key,
                                    key,
                                  ])
                                //setConfirm(["dDocs", "passportDocs", key])
                                // setConfirm([
                                //   "docs-single",
                                //   "passportDocs",
                                //   0,
                                //   key,
                                // ])
                              }
                            />
                          </div>
                        ))}
                        { }
                        <p className="error-txt">{errors?.passportDocs}</p> */}

                        {isEuDocs && (
                          <>
                            <FileUpload
                              multiple={false}
                              label="EU share code"
                              onSuccess={(files) =>
                                addDocs("euDocs", files, false, 0)
                              }
                            />
                            {/* already added */}
                            {fields?.euDocs && !docs?.euDocs ? (
                              <div className="d-flex align-items-center justify-content-between pt-3">
                                <p
                                  className="agt-proof-file mb-0 et-link"
                                  onClick={() =>
                                    common.openCloudUrl(fields.euDocs)
                                  }
                                >
                                  {common.fileName(fields.euDocs)}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() => {
                                    setConfirm([
                                      "docs-single-fields",
                                      "euDocs",
                                      0,
                                      0,
                                    ]);
                                  }}
                                />
                              </div>
                            ) : null}
                            {/* newly added */}
                            {docs?.euDocs?.map((item, key) => (
                              <div
                                className="d-flex align-items-center justify-content-between pt-3"
                                key={key}
                              >
                                <p className="agt-proof-file mb-0">
                                  {item.name}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() =>
                                    setConfirm([
                                      "docs-single",
                                      "euDocs",
                                      key,
                                      key,
                                    ])
                                  }
                                />
                              </div>
                            ))}
                            {}
                            <p className="error-txt">{errors?.euDocs}</p>
                          </>
                        )}
                        {isBrpDocs && (
                          <>
                            <FileUpload
                              multiple={false}
                              label="Biometric Residence Permit (BRP)"
                              onSuccess={(files) =>
                                addDocs("brpDocs", files, false, 0)
                              }
                            />
                            {/* already added */}
                            {fields?.brpDocs && !docs?.brpDocs ? (
                              <div className="d-flex align-items-center justify-content-between pt-3">
                                <p
                                  className="agt-proof-file mb-0 et-link"
                                  onClick={() =>
                                    common.openCloudUrl(fields.brpDocs)
                                  }
                                >
                                  {common.fileName(fields.brpDocs)}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() => {
                                    setConfirm([
                                      "docs-single-fields",
                                      "brpDocs",
                                      0,
                                      0,
                                    ]);
                                  }}
                                />
                              </div>
                            ) : null}
                            {/* newly added */}
                            {docs?.brpDocs?.map((item, key) => (
                              <div
                                className="d-flex align-items-center justify-content-between pt-3"
                                key={key}
                              >
                                <p className="agt-proof-file mb-0">
                                  {item.name}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() =>
                                    setConfirm([
                                      "docs-single",
                                      "brpDocs",
                                      key,
                                      key,
                                    ])
                                  }
                                />
                              </div>
                            ))}
                            {}
                            <p className="error-txt">{errors?.brpDocs}</p>
                          </>
                        )}
                      </Row>
                      <div className="ref-div" ref={workDocRef}></div>
                    </div>
                  </Fragment>
                )}

                {["address", "personal_address"].includes(formModule) && (
                  <Fragment>
                    <div className="register-step-header d-flex justify-content-between align-items-center">
                      <p className="register-step-title mb-0">Address</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="register-step-content">
                      <Row>
                        {InputElement(
                          "addressWithRegion",
                          "Address Line 1",
                          12
                        )}

                        {InputElement("address2", "Address Line 2", 12)}

                        {InputElement("province", "province")}
                        {InputElement("city", "City / Town")}
                        {SelectServerElement(
                          "countrySelect",
                          "country",
                          "Country"
                        )}

                        {/* <Col md={6}>
                        <label className="form-label">Province</label>
                      <div className="signin-form-row">
                        <FieldSelect
                          api="common_state"
                          query={`/${fields?.country?.value}?&offset=1&limit=1000`}
                          get="name"
                          set="name"
                          value={fields.province}
                          placeholder="Please select the province"
                          render={selectRender.province}
                          clearable={true}
                          findValue={false}
                          multi={false}
                          auth="token"
                          onSelect={(data) => {
                            setFields((prev) => ({
                              ...prev,
                              province: data,
                              city:null
                            }));
                            setSelectRender((prev) => ({
                              ...prev,
                              city: data === null ? "empty" : new Date(),
                            }));
                          }}
                         
                        />
                        <p className="error-txt">
                          {errors?.province}
                        </p>
                        </div>

                        </Col>
                        <Col md={6}>

                        <label className="form-label">City / Town</label>
                      <div className="signin-form-row">
                        <FieldSelect
                          api="common_city_v2"
                          query={`/${fields?.country?.value}/${fields?.province?.value}?&offset=1&limit=1000`}
                          get="name"
                          set="name"
                          value={fields.city}
                          placeholder="Please select the city"
                          render={selectRender.city}
                          clearable={true}
                          findValue={false}
                          multi={false}
                          auth="token"
                          onSelect={(data) => {
                            setFields((prev) => ({
                              ...prev,
                              city: data,
                            }));
                          }}
                         
                        />
                        <p className="error-txt">
                          {errors?.city}
                        </p>
                        </div>
                        </Col> */}

                        {InputElement("postalCode", "Postal Code")}
                        {InputElement(
                          "email",
                          "Email",
                          6,
                          authData.role === "student" ? true : false
                        )}
                        {MobileInputElement("mobileCode", "mobile", "Mobile")}
                      </Row>
                    </div>
                  </Fragment>
                )}
                <Col lg={12} className="register-step-footernew">
                  <Link to="#">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit()}
                      disabled={loading}
                    >
                      {loading ? "Procesing..." : "Save & Update"}
                    </button>
                  </Link>
                </Col>
              </div>
            </Fragment>
          )}
          {["education", "college", "education_college"].includes(
            formModule
          ) && (
            <Fragment>
              <div className={!formModal ? "" : ""}>
                {["education", "education_college"].includes(formModule) && (
                  <Fragment>
                    {!formModal && (
                      <div className="register-step-header-general">
                        <p className="register-step-title mb-0">
                          Educational Information
                        </p>
                      </div>
                    )}
                    <div className="register-step-header d-flex justify-content-between align-items-center">
                      <p className="register-step-title mb-0">General</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="register-step-content">
                      <Row>
                        {SelectElement(
                          "educationLevelProfile",
                          "highLevelOfEducation",
                          "Highest level of Education"
                        )}
                        {!isOtherQualy && !isWorkExp && (
                          <>
                            {SelectServerElement(
                              "countrySelect",
                              "countryOfEducation",
                              "Country of Education"
                            )}

                            {InputElement("grade", "Credits")}
                          </>
                        )}

                        {isOtherQualy && !isWorkExp && (
                          <>
                            {InputElement(
                              "otherQualification",
                              "Qualification"
                            )}
                            {InputAreaElement(
                              "otherQualificationDesc",
                              "Description"
                            )}

                            <FileUpload
                              multiple={false}
                              label="Qualification proof"
                              onSuccess={(files) =>
                                addDocs(
                                  "otherQualificationDocs",
                                  files,
                                  true,
                                  0,
                                  true
                                )
                              }
                            />
                            {/* already added */}
                            {fields?.otherQualificationDocs ? (
                              <>
                                {fields?.otherQualificationDocs.map(
                                  (item, key) => {
                                    return (
                                      <div className="d-flex align-items-center justify-content-between pt-3">
                                        <p
                                          className="agt-proof-file mb-0 et-link"
                                          onClick={() =>
                                            item.match(/live|dev|homeneu/)
                                              ? common.openCloudUrl(item)
                                              : null
                                          }
                                        >
                                          {item.match(/live|dev|homeneu/)
                                            ? common.fileName(item)
                                            : item}
                                        </p>
                                        <img
                                          src={common.loadImg(
                                            "iconly-dark-delete.svg"
                                          )}
                                          className="et-cp"
                                          onClick={() =>
                                            setConfirm([
                                              "dDocs",
                                              "otherQualificationDocs",
                                              key,
                                            ])
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : null}

                            {}
                            <p className="error-txt">
                              {errors?.otherQualificationDocs}
                            </p>
                          </>
                        )}
                        {!isOtherQualy && isWorkExp && (
                          <>
                            {SelectElement(
                              "yearOfExp",
                              "yearOfExp",
                              "Year Of Experience"
                            )}
                            <Row className="d-flex justify-content-between align-items-center maincont pe-0">
                              <Col lg={6} className="bginfocontent">
                                <p>Are you currently working?</p>
                              </Col>
                              <Col
                                lg={6}
                                className="d-flex align-items-center justify-content-end pe-0"
                              >
                                <div className="bgbutton">
                                  <input
                                    type="radio"
                                    id="a25"
                                    name="check-substitution-2"
                                    className="form-control"
                                    checked={fields.ruWorking === "Yes"}
                                    onChange={() =>
                                      setFields((prev) => ({
                                        ...prev,
                                        ruWorking: "Yes",
                                      }))
                                    }
                                  />
                                  <label
                                    className="btn btn-default"
                                    htmlFor="a25"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="bgbutton margino">
                                  <input
                                    type="radio"
                                    id="a50"
                                    name="check-substitution-2"
                                    className="form-control"
                                    checked={fields.ruWorking === "No"}
                                    onChange={() =>
                                      setFields((prev) => ({
                                        ...prev,
                                        ruWorking: "No",
                                      }))
                                    }
                                  />
                                  <label
                                    className="btn btn-default"
                                    htmlFor="a50"
                                  >
                                    No
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <FileUpload
                              label="Proof of Evidence"
                              multiple={true}
                              onSuccess={(files) =>
                                addDocs("expDocs", files, true, 0, true)
                              }
                            />
                            {/* already added */}

                            {fields?.expDocs ? (
                              <>
                                {fields?.expDocs.map((item, key) => {
                                  return (
                                    <div className="d-flex align-items-center justify-content-between pt-3">
                                      <p
                                        className="agt-proof-file mb-0 et-link"
                                        onClick={() =>
                                          item.match(/live|dev|homeneu/)
                                            ? common.openCloudUrl(item)
                                            : null
                                        }
                                      >
                                        {item.match(/live|dev|homeneu/)
                                          ? common.fileName(item)
                                          : item}
                                      </p>
                                      <img
                                        src={common.loadImg(
                                          "iconly-dark-delete.svg"
                                        )}
                                        className="et-cp"
                                        onClick={() =>
                                          setConfirm(["dDocs", "expDocs", key])
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            ) : null}
                            {}
                            <p className="error-txt">{errors?.expDocs}</p>
                          </>
                        )}
                      </Row>
                    </div>
                  </Fragment>
                )}
                {["college", "education_college"].includes(formModule) && (
                  <Fragment>
                    <div className="register-step-header d-flex justify-content-between align-items-center">
                      <p className="register-step-title mb-0">College</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    {fields.college.map((i, k) => {
                      let allow = false;
                      if (formIndex === "all") {
                        allow = true;
                      } else if (
                        formIndex === "new" &&
                        fields.college.length === Number(k) + 1
                      ) {
                        allow = true;
                      } else if (formIndex === k) {
                        allow = true;
                      }
                      if (allow) {
                        return (
                          <Fragment key={k}>
                            {formAddNew && (
                              <div className="register-step-header1 brderclr d-flex justify-content-between">
                                <p className="titlle">0{Number(k) + 1}</p>
                                {k !== 0 ? (
                                  <p
                                    className="newtitle"
                                    onClick={() => removeNew("college", k)}
                                  >
                                    <img
                                      src={common.loadImg("delete-icn.svg")}
                                      className="ms-3 et-cp"
                                    />
                                  </p>
                                ) : null}
                              </div>
                            )}
                            <div className="register-step-content">
                              <Row>
                                {SelectServerElement(
                                  "countrySelect",
                                  "institutionCountry",
                                  "Country of Institution",
                                  6,
                                  "college",
                                  k
                                )}

                                {InputElement(
                                  "institutionName",
                                  "Name of Institution",
                                  6,
                                  false,
                                  "college",
                                  k
                                )}
                                {InputElement(
                                  "qualification",
                                  "Qualification",
                                  6,
                                  false,
                                  "college",
                                  k
                                )}
                                {InputElement(
                                  "degree",
                                  "Degree Name",
                                  6,
                                  false,
                                  "college",
                                  k
                                )}
                                {DatePickerElement(
                                  "startDate",
                                  "Start Date",
                                  6,
                                  "college",
                                  k
                                )}
                                {DatePickerElement(
                                  "endDate",
                                  "End Date",
                                  6,
                                  "college",
                                  k
                                )}
                                {DatePickerElement(
                                  "graduationDate",
                                  "Graduation Date",
                                  6,
                                  "college",
                                  k
                                )}
                                {SelectElement(
                                  "gradingSystem",
                                  "gradingSystem",
                                  "Grading System",
                                  6,
                                  "college",
                                  k
                                )}
                                {InputElement(
                                  "score",
                                  "Credits",
                                  6,
                                  false,
                                  "college",
                                  k
                                )}

                                <FileUpload
                                  id={k}
                                  multiple={false}
                                  onSuccess={(files) =>
                                    addDocs("college", files, true, k)
                                  }
                                />
                                {/* already added */}
                                {fields?.college[k]?.docs?.length > 0 &&
                                  fields?.college[k]?.docs?.map((item, key) => (
                                    <div
                                      className="d-flex align-items-center justify-content-between pt-3"
                                      key={key}
                                    >
                                      <p
                                        className="agt-proof-file mb-0 et-link"
                                        onClick={() =>
                                          item.match(/live|dev|homeneu/)
                                            ? common.openCloudUrl(item)
                                            : null
                                        }
                                      >
                                        {item.match(/live|dev|homeneu/)
                                          ? common.fileName(item)
                                          : item}
                                      </p>
                                      <img
                                        src={common.loadImg(
                                          "iconly-dark-delete.svg"
                                        )}
                                        className="et-cp"
                                        onClick={() => {
                                          setConfirm([
                                            "docs",
                                            "college",
                                            k,
                                            key,
                                          ]);
                                        }}
                                      />
                                    </div>
                                  ))}
                                {/* newly added */}
                                {/* {docs.college[k].map((item, key) => (
                                  <div
                                    className="d-flex align-items-center justify-content-between pt-3"
                                    key={key}
                                  >
                                    <p className="agt-proof-file mb-0">
                                      {item.name}
                                    </p>
                                    <img
                                      src={common.loadImg(
                                        "iconly-dark-delete.svg"
                                      )}
                                      className="et-cp"
                                      onClick={() =>
                                        setConfirm([
                                          "fields",
                                          "college",
                                          k,
                                          key,
                                        ])
                                      }
                                    />
                                  </div>
                                ))} */}

                                <p className="error-txt">
                                  {errors.college[k].docs}
                                </p>
                              </Row>
                              <div className="ref-div" ref={workDocRef}></div>
                            </div>
                          </Fragment>
                        );
                      }
                    })}
                  </Fragment>
                )}

                {formAddNew && (
                  <div className="register-step-header1">
                    <p className="newtitle" onClick={() => addNew("college")}>
                      0{Number(fields.college.length) + 1}. Add another
                      Institute{" "}
                      <img
                        src={common.loadImg("plus-icn-blue.svg")}
                        className="ms-3 et-cp"
                      />
                    </p>
                  </div>
                )}

                <div className="bgclasss">&nbsp;</div>
                <div className="register-step-footernew">
                  <Link to="">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit()}
                      disabled={loading}
                    >
                      {loading ? "Procesing..." : "Save & Update"}
                    </button>
                  </Link>
                </div>
              </div>
            </Fragment>
          )}
          {/* hiding TheExam */}
          {["hidingTheExam"].includes(formModule) && (
            <Fragment>
              <div className={!formModal ? "" : ""}>
                {["exam", "exam_background"].includes(formModule) && (
                  <Fragment>
                    <div
                      className={`register-step-header${
                        !formModal ? "-general" : ""
                      } d-flex justify-content-between align-items-center`}
                    >
                      <p className="register-step-title mb-0">Test Course</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    {fields.exam.map((i, k) => {
                      let allow = false;
                      if (formIndex === "all") {
                        allow = true;
                      } else if (
                        formIndex === "new" &&
                        fields.exam.length === Number(k) + 1
                      ) {
                        allow = true;
                      } else if (formIndex === k) {
                        allow = true;
                      }
                      if (allow) {
                        return (
                          <Fragment key={k}>
                            {formAddNew && (
                              <div className="register-step-header1 brderclr d-flex justify-content-between">
                                <p className="titlle">0{Number(k) + 1}.</p>
                                {k !== 0 ? (
                                  <p
                                    className="newtitle"
                                    onClick={() => removeNew("exam", k)}
                                  >
                                    <img
                                      src={common.loadImg("delete-icn.svg")}
                                      className="ms-3 et-cp"
                                    />
                                  </p>
                                ) : null}
                              </div>
                            )}
                            <div className="register-step-content">
                              <Row>
                                {SelectElement(
                                  "test",
                                  "examType",
                                  "Test Name",
                                  6,
                                  "exam",
                                  k
                                )}
                                <Col md={6}>
                                  <label className="form-label">Score</label>
                                  <div className="signin-form-row">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="chk-btn-wrap me-3">
                                        <input
                                          type="text"
                                          name="listening"
                                          className="form-control"
                                          placeholder="Listening"
                                          value={fields.exam[k].listening}
                                          onChange={(e) =>
                                            onInputChangeScore(e, k)
                                          }
                                        />
                                      </div>
                                      <div className="chk-btn-wrap me-3">
                                        <input
                                          type="text"
                                          name="reading"
                                          className="form-control"
                                          placeholder="Reading"
                                          value={fields.exam[k].reading}
                                          onChange={(e) =>
                                            onInputChangeScore(e, k)
                                          }
                                        />
                                      </div>
                                      <div className="chk-btn-wrap me-3">
                                        <input
                                          type="text"
                                          name="writing"
                                          className="form-control"
                                          placeholder="Writing"
                                          value={fields.exam[k].writing}
                                          onChange={(e) =>
                                            onInputChangeScore(e, k)
                                          }
                                        />
                                      </div>
                                      <div className="chk-btn-wrap">
                                        <input
                                          type="text"
                                          name="speaking"
                                          className="form-control"
                                          placeholder="Speaking"
                                          value={fields.exam[k].speaking}
                                          onChange={(e) =>
                                            onInputChangeScore(e, k)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                {DatePickerElement(
                                  "dateOfIssue",
                                  "Date Of Issue",
                                  6,
                                  "exam",
                                  k
                                )}

                                {InputElement(
                                  "testScore",
                                  "Overall Score",
                                  6,
                                  true,
                                  "exam",
                                  k
                                )}

                                <FileUpload
                                  id={k}
                                  onSuccess={(files) =>
                                    addDocs("exam", files, true, k)
                                  }
                                />
                                {/* already added */}
                                {fields.exam[k].docs.map((item, key) => (
                                  <div
                                    className="d-flex align-items-center justify-content-between pt-3"
                                    key={key}
                                  >
                                    <p
                                      className="agt-proof-file mb-0 et-link"
                                      onClick={() =>
                                        item.match(/live|dev|homeneu/)
                                          ? common.fileName(item)
                                          : null
                                      }
                                    >
                                      {item.match(/live|dev|homeneu/)
                                        ? common.fileName(item)
                                        : item}
                                    </p>
                                    <img
                                      src={common.loadImg(
                                        "iconly-dark-delete.svg"
                                      )}
                                      className="et-cp"
                                      onClick={() =>
                                        setConfirm(["docs", "exam", k, key])
                                      }
                                    />
                                  </div>
                                ))}
                                {/* newly added */}
                                {docs.exam[k].map((item, key) => (
                                  <div
                                    className="d-flex align-items-center justify-content-between pt-3"
                                    key={key}
                                  >
                                    <p className="agt-proof-file mb-0">
                                      {item.name}
                                    </p>
                                    <img
                                      src={common.loadImg(
                                        "iconly-dark-delete.svg"
                                      )}
                                      className="et-cp"
                                      onClick={() =>
                                        setConfirm(["fields", "exam", k, key])
                                      }
                                    />
                                  </div>
                                ))}
                              </Row>
                              <div className="ref-div" ref={workDocRef}></div>
                            </div>
                          </Fragment>
                        );
                      }
                    })}

                    {formAddNew && (
                      <div className="register-step-header1">
                        <p className="newtitle" onClick={() => addNew("exam")}>
                          0{Number(fields.exam.length) + 1}. Add another Test{" "}
                          <img
                            src={common.loadImg("plus-icn-blue.svg")}
                            className="ms-3 et-cp"
                          />
                        </p>
                      </div>
                    )}
                    <div className="bgclasss">&nbsp;</div>
                  </Fragment>
                )}

                {["background", "exam_background"].includes(formModule) && (
                  <Fragment>
                    <div
                      className={`register-step-header${
                        !formModal ? "-general" : ""
                      } d-flex justify-content-between align-items-center`}
                    >
                      <p className="register-step-title mb-0">
                        Background Information
                      </p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="register-step-content">
                      <Row className="d-flex justify-content-between align-items-center maincont">
                        <Col lg={6} className="bginfocontent">
                          <p>Have You Applied for Student Finance before?</p>
                        </Col>
                        <Col
                          lg={6}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <div className="bgbutton">
                            <input
                              type="radio"
                              id="a25"
                              name="check-substitution-2"
                              className="form-control"
                              checked={fields.isFinanceTakenBefore === "Y"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  isFinanceTakenBefore: "Y",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a25">
                              Yes
                            </label>
                          </div>
                          <div className="bgbutton margino">
                            <input
                              type="radio"
                              id="a50"
                              name="check-substitution-2"
                              className="form-control"
                              checked={fields.isFinanceTakenBefore === "N"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  isFinanceTakenBefore: "N",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a50">
                              No
                            </label>
                          </div>
                          <p className="error-txt">
                            {errors?.isFinanceTakenBefore}
                          </p>
                        </Col>
                      </Row>
                      {/* drop down */}
                      {fields.isFinanceTakenBefore === "Y" && (
                        <Row className="d-flex justify-content-between align-items-center maincont">
                          <Col lg={6} className="bginfocontent">
                            <p>
                              How many years of funding have you taken before?
                            </p>
                          </Col>
                          {SelectElement(
                            "financeYearTakenBefore",
                            "financeYearTakenBefore",
                            ""
                          )}
                        </Row>
                      )}

                      {true && (
                        <Row className="d-flex justify-content-between align-items-center maincont">
                          <Col lg={6} className="bginfocontent">
                            <p>What is your Visa Status?</p>
                          </Col>
                          {SelectElement("visaStatus", "visaStatus", "")}
                        </Row>
                      )}

                      {true && (
                        <Row className="d-flex justify-content-between align-items-center maincont">
                          <Col lg={6} className="bginfocontent">
                            <p>How long have you been living in UK?</p>
                          </Col>
                          {SelectElement("livingPeriod", "livingPeriod", "")}
                        </Row>
                      )}

                      {/* ./drop down */}
                      <Row className="d-flex justify-content-between align-items-center mt-3">
                        <Col lg={6} className="bginfocontent">
                          <p>Are you a Refugee/ Asylum Seeker?</p>
                        </Col>
                        <Col
                          lg={6}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <div className="bgbutton">
                            <input
                              type="radio"
                              id="a27"
                              name="check-substitution-3"
                              className="form-control"
                              checked={fields?.refugee === "Y"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  refugee: "Y",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a27">
                              Yes
                            </label>
                          </div>
                          <div className="bgbutton margino">
                            <input
                              type="radio"
                              id="a28"
                              name="check-substitution-3"
                              className="form-control"
                              checked={fields.refugee === "N"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  refugee: "N",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a28">
                              No
                            </label>
                          </div>
                          <p className="error-txt">{errors?.refugee}</p>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between align-items-center mt-3">
                        <Col lg={6} className="bginfocontent">
                          <p>
                            Have you spent any time in public care up to the age
                            of 18?
                          </p>
                        </Col>
                        <Col
                          lg={6}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <div className="bgbutton">
                            <input
                              type="radio"
                              id="a29"
                              name="check-substitution-4"
                              className="form-control"
                              checked={fields.publicCare === "Y"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  publicCare: "Y",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a29">
                              Yes
                            </label>
                          </div>
                          <div className="bgbutton margino">
                            <input
                              type="radio"
                              id="a30"
                              name="check-substitution-4"
                              className="form-control"
                              checked={fields.publicCare === "N"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  publicCare: "N",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a30">
                              No
                            </label>
                          </div>
                          <p className="error-txt">{errors?.publicCare}</p>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between align-items-center mt-3">
                        <Col lg={6} className="bginfocontent">
                          <p>Disability/special needs?</p>
                        </Col>
                        <Col
                          lg={6}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="bgbutton">
                            <input
                              type="radio"
                              id="a26"
                              name="check-substitution-5"
                              className="form-control"
                              checked={fields.disability === "Y"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  disability: "Y",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a26">
                              Yes
                            </label>
                          </div>
                          <div className="bgbutton margino">
                            <input
                              type="radio"
                              id="a51"
                              name="check-substitution-5"
                              className="form-control"
                              checked={fields.disability === "N"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  disability: "N",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a51">
                              No
                            </label>
                          </div>
                          <p className="error-txt">{errors?.disability}</p>
                        </Col>
                      </Row>
                    </div>
                  </Fragment>
                )}

                <div className="register-step-footernew">
                  {formType === "agentNext" && (
                    <Link to={`/agent/student/edit/${id}/${nav[type]}/2`}>
                      <button className="btn-default mx-3">Skip</button>
                    </Link>
                  )}
                  <Link to="">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit()}
                      disabled={loading}
                    >
                      {loading ? "Procesing..." : "Save & Update"}
                    </button>
                  </Link>
                </div>
              </div>
            </Fragment>
          )}

          {["background", "exam_background"].includes(formModule) && (
            <Fragment>
              <div
                className={`register-step-header${
                  !formModal ? "-general" : ""
                } d-flex justify-content-between align-items-center`}
              >
                <p className="register-step-title mb-0">
                  Background Information
                </p>
                {formModal && (
                  <div className="cp" onClick={props?.onCancel}>
                    <img
                      src={common.loadImg("close-icn.svg")}
                      alt=""
                      className="et-cp"
                    />
                  </div>
                )}
              </div>
              <div className="register-step-content">
                {(fields?.birth?.value !== "GB" || fields?.birth == "") &&
                  InputElement("passportNumber", "Passport Number")}

                <FileUpload
                  multiple={true}
                  label={
                    fields.birth && fields.birth.value == "GB"
                      ? "Birth Certificate or Proof of ID Document"
                      : "Passport Document"
                  }
                  onSuccess={(files) =>
                    addDocs("passportDocs", files, true, 0, true)
                  }
                />
                {/* already added */}
                {fields?.passportDocs.length > docs?.passportDocs.length ? (
                  <>
                    {typeof fields?.passportDocs !== "string" ? (
                      fields?.passportDocs.map((item, key) => {
                        return item.match(/live|dev|homeneu/) ? (
                          <div className="d-flex align-items-center justify-content-between pt-3">
                            <p
                              className="agt-proof-file mb-0 et-link"
                              onClick={() =>
                                item.match(/live|dev|homeneu/)
                                  ? common.openCloudUrl(item)
                                  : null
                              }
                            >
                              {item.match(/live|dev|homeneu/)
                                ? common.fileName(item)
                                : item}
                            </p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={() =>
                                setConfirm(["dDocs", "passportDocs", key])
                              }
                            />
                          </div>
                        ) : null;
                      })
                    ) : (
                      <div className="d-flex align-items-center justify-content-between pt-3">
                        <p
                          className="agt-proof-file mb-0 et-link"
                          onClick={() =>
                            common.openCloudUrl(fields.passportDocs)
                          }
                        >
                          {common.fileName(fields.passportDocs)}
                        </p>
                        <img
                          src={common.loadImg("iconly-dark-delete.svg")}
                          className="et-cp"
                          onClick={() => {
                            setConfirm([
                              "docs-single-fields",
                              "passportDocs",
                              0,
                              0,
                            ]);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : null}

                {/* newly added */}
                {docs?.passportDocs?.map((item, key) => (
                  <div
                    className="d-flex align-items-center justify-content-between pt-3"
                    key={key}
                  >
                    <p className="agt-proof-file mb-0">{item.name}</p>
                    <img
                      src={common.loadImg("iconly-dark-delete.svg")}
                      className="et-cp"
                      onClick={
                        () => setConfirm(["dFields", "passportDocs", key, key])
                        //setConfirm(["dDocs", "passportDocs", key])
                        // setConfirm([
                        //   "docs-single",
                        //   "passportDocs",
                        //   0,
                        //   key,
                        // ])
                      }
                    />
                  </div>
                ))}
                {}
                <p className="error-txt">{errors?.passportDocs}</p>
                <Row className="d-flex justify-content-between align-items-center maincont">
                  <Col lg={6} className="bginfocontent">
                    <p>Have You Applied for Student Finance before?</p>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="bgbutton">
                        <input
                          type="radio"
                          id="a25"
                          name="check-substitution-2"
                          className="form-control"
                          checked={fields.isFinanceTakenBefore === "Y"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              isFinanceTakenBefore: "Y",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a25">
                          Yes
                        </label>
                      </div>
                      <div className="bgbutton margino">
                        <input
                          type="radio"
                          id="a50"
                          name="check-substitution-2"
                          className="form-control"
                          checked={fields.isFinanceTakenBefore === "N"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              isFinanceTakenBefore: "N",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a50">
                          No
                        </label>
                      </div>
                    </div>
                    <p className="error-txt">{errors?.isFinanceTakenBefore}</p>
                  </Col>
                </Row>
                {/* drop down */}
                {fields.isFinanceTakenBefore === "Y" && (
                  <Row className="d-flex justify-content-between align-items-center maincont">
                    <Col lg={6} className="bginfocontent">
                      <p>How many years of funding have you taken before?</p>
                    </Col>
                    {SelectElement(
                      "financeYearTakenBefore",
                      "financeYearTakenBefore",
                      ""
                    )}
                  </Row>
                )}

                {true && (
                  <Row className="d-flex justify-content-between align-items-center maincont">
                    <Col lg={6} className="bginfocontent">
                      <p>What is your Visa Status?</p>
                    </Col>
                    {SelectElement("visaStatus", "visaStatus", "")}
                  </Row>
                )}

                {true && (
                  <Row className="d-flex justify-content-between align-items-center maincont">
                    <Col lg={6} className="bginfocontent">
                      <p>How long have you been living in UK?</p>
                    </Col>
                    {SelectElement("livingPeriod", "livingPeriod", "")}
                  </Row>
                )}

                {/* ./drop down */}
                <Row className="d-flex justify-content-between align-items-center mt-3">
                  <Col lg={6} className="bginfocontent">
                    <p>Are you a Refugee/ Asylum Seeker?</p>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="bgbutton">
                        <input
                          type="radio"
                          id="a27"
                          name="check-substitution-3"
                          className="form-control"
                          checked={fields?.refugee === "Y"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              refugee: "Y",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a27">
                          Yes
                        </label>
                      </div>
                      <div className="bgbutton margino">
                        <input
                          type="radio"
                          id="a28"
                          name="check-substitution-3"
                          className="form-control"
                          checked={fields.refugee === "N"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              refugee: "N",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a28">
                          No
                        </label>
                      </div>
                    </div>

                    <p className="error-txt">{errors?.refugee}</p>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between align-items-center mt-3">
                  <Col lg={6} className="bginfocontent">
                    <p>
                      Have you spent any time in public care up to the age of
                      18?
                    </p>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="bgbutton">
                        <input
                          type="radio"
                          id="a29"
                          name="check-substitution-4"
                          className="form-control"
                          checked={fields.publicCare === "Y"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              publicCare: "Y",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a29">
                          Yes
                        </label>
                      </div>
                      <div className="bgbutton margino">
                        <input
                          type="radio"
                          id="a30"
                          name="check-substitution-4"
                          className="form-control"
                          checked={fields.publicCare === "N"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              publicCare: "N",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a30">
                          No
                        </label>
                      </div>
                    </div>

                    <p className="error-txt">{errors?.publicCare}</p>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between align-items-center mt-3">
                  <Col lg={6} className="bginfocontent">
                    <p>Disability/special needs?</p>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="bgbutton">
                        <input
                          type="radio"
                          id="a26"
                          name="check-substitution-5"
                          className="form-control"
                          checked={fields.disability === "Y"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              disability: "Y",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a26">
                          Yes
                        </label>
                      </div>
                      <div className="bgbutton margino">
                        <input
                          type="radio"
                          id="a51"
                          name="check-substitution-5"
                          className="form-control"
                          checked={fields.disability === "N"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              disability: "N",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a51">
                          No
                        </label>
                      </div>
                    </div>

                    <p className="error-txt">{errors?.disability}</p>
                  </Col>
                </Row>
              </div>
              <div className="register-step-footernew">
                <Link to="">
                  <button
                    className="btn-default"
                    onClick={() => onSubmit()}
                    disabled={loading}
                  >
                    {loading ? "Procesing..." : "Save & Update"}
                  </button>
                </Link>
              </div>
            </Fragment>
          )}

          {["workExp"].includes(formModule) && (
            <Fragment>
              <div className={!formModal ? "" : ""}>
                <div
                  className={`register-step-header${
                    !formModal ? "-general" : ""
                  } d-flex justify-content-between align-items-between`}
                >
                  <p className="register-step-title mb-0">Work History</p>

                  {formModal && (
                    <div className="cp" onClick={props?.onCancel}>
                      <img
                        src={common.loadImg("close-icn.svg")}
                        alt=""
                        className="et-cp"
                      />
                    </div>
                  )}
                </div>
                {fields.workExp.map((i, k) => {
                  let allow = false;
                  if (formIndex === "all") {
                    allow = true;
                  } else if (
                    formIndex === "new" &&
                    fields.workExp.length === Number(k) + 1
                  ) {
                    allow = true;
                  } else if (formIndex === k) {
                    allow = true;
                  }
                  if (allow) {
                    return (
                      <Fragment key={k}>
                        {formAddNew && (
                          <div className="register-step-header1 brderclr d-flex justify-content-between">
                            <p className="titlle">0{Number(k) + 1}.</p>
                            {k !== 0 ? (
                              <p
                                className="newtitle"
                                onClick={() => removeNew("workExp", k)}
                              >
                                <img
                                  src={common.loadImg("delete-icn.svg")}
                                  className="ms-3 et-cp"
                                />
                              </p>
                            ) : null}
                          </div>
                        )}
                        <div className="register-step-content">
                          <Row>
                            {InputElement(
                              "companyName",
                              "Company Name",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "designation",
                              "Designation",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "lineManagerName",
                              "Line Manager Name",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "lineManagerEmail",
                              "Line Manager Work Email",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "lineManagerDesignation",
                              "Line Manager Designation",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "companyEmail",
                              "Company Email",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "companyAddress",
                              "Company Address",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {DatePickerElement(
                              "joinDate",
                              "Joining Date",
                              6,
                              "workExp",
                              k
                            )}
                            {DatePickerElement(
                              "endDate",
                              "End Date",
                              6,
                              "workExp",
                              k
                            )}
                            <FileUpload
                              id={k}
                              multiple={false}
                              onSuccess={(files) =>
                                addDocs("workExp", files, true, k)
                              }
                            />
                            {/* already added */}
                            {fields.workExp[k].docs.map((item, key) => (
                              <div
                                className="d-flex align-items-center justify-content-between pt-3"
                                key={key}
                              >
                                <p
                                  className="agt-proof-file mb-0 et-link"
                                  onClick={() =>
                                    item.match(/live|dev|homeneu/)
                                      ? common.openCloudUrl(item)
                                      : null
                                  }
                                >
                                  {item.match(/live|dev|homeneu/)
                                    ? common.fileName(item)
                                    : item}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() =>
                                    setConfirm(["docs", "workExp", k, key])
                                  }
                                />
                              </div>
                            ))}
                            {/* newly added */}
                            {/* {docs.workExp[k].map((item, key) => (
                              <div
                                className="d-flex align-items-center justify-content-between pt-3"
                                key={key}
                              >
                                <p className="agt-proof-file mb-0">
                                  {item.name}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() =>
                                    setConfirm(["fields", "workExp", k, key])
                                  }
                                />
                              </div>
                            ))} */}
                          </Row>
                          <div className="ref-div" ref={workDocRef}></div>
                        </div>
                      </Fragment>
                    );
                  }
                })}
                {formAddNew && (
                  <div className="register-step-header1">
                    <p className="newtitle" onClick={() => addNew("workExp")}>
                      0{Number(fields.workExp.length) + 1}. Add another Company{" "}
                      <img
                        src={common.loadImg("plus-icn-blue.svg")}
                        className="ms-3 et-cp"
                      />
                    </p>
                  </div>
                )}
                <div className="bgclasss">&nbsp;</div>

                <div className="register-step-footernew">
                  {formType === "agentNext" && (
                    <Link to={`/agent/student/edit/${id}/${nav[type]}/2`}>
                      <button className="btn-default mx-3">Skip</button>
                    </Link>
                  )}

                  <Link to="">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit()}
                      disabled={loading}
                    >
                      {loading ? "Procesing..." : "Save & Update"}
                    </button>
                  </Link>
                </div>
              </div>
            </Fragment>
          )}
          <div className="shadow-div m-0"></div>
          <div className="greenshow-div"></div>
        </Fragment>
      )}
      {confirm && <ModalConfirm onYes={onYes} onNo={() => setConfirm(null)} />}
    </Fragment>
  );
}
export default StudentForm;
