import AmlHashForm from "aml-hash-form";
import { useState } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
//includes
import { common, config } from "helpers";
import { api } from "helpers/api";

function ForgotPassword() {
  // init
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = useParams();
  //state
  const [localData] = useState(common.localDataGet());
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        // "email#Please enter a valid email address",
      ],
      value: localData?.email,
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  //api
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      otpGenerate();
    }
  };

  //api
  const otpGenerate = () => {
    setLoading(true);
    let payload = {
      email: fields.email,
      type: "forgot",
    };
    // if (type === "agent") {
    //   payload["userType"] = "AGENT";
    // } else {
    //   payload["userType"] = "STUDENT";
    // }
    let data = {
      url: "auth_otp_generate",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res) => {
      if (res.status === 500) {
        setError(res.data.error);
      } else if (res.status === 200) {
        common.localDataSet({
          email: fields.email,
          type: "passwordForgot",
        });
        console.log(type, "=================type");
        if (type == "student") {
          navigate("/student/otp/verify");
        } else {
          navigate("/agent/otp/verify");
        }
      }
      setLoading(false);
    });
  };

  //render
  return (
    <>
      {/* <div className="container-fluid content-wrap">
        <div className="row mt-5 mx-2">
          <div className="col-md-5 mt-4 mx-auto">
            <div className="box-header">
              <p className="title">Forgot Password</p>
            </div>
            <div className="box-body">
              <label className="form-label">Email Address</label>
              <div className="et-form-row">
                <input
                  type="email"
                  name="email"
                  className="form-control input-email"
                  value={fields.email}
                  placeholder="Enter your email address"
                  onChange={(e) => {
                    setError(null);
                    handleChange(e);
                  }}
                />
                <div className=" email-fieldcheckbox checkbox-design">
                  <div className="input-tick"></div>
                </div>
                <p className="error-txt">{error ? error : errors.email}</p>
              </div>

              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn-default"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Submit"}
                </button>
              </div>
            </div>
            <div className="shadow-div"></div>
            <div className="greenshow-div"></div>
          </div>
        </div>
      </div> */}
      {location?.pathname === "/agent/password/forgot" && (
        <div className="container-fluid p-0">
          <div className="auth-wrap promoter-wrap auth-signup ">
            <div className="signup-right">
              <div className="inner-wrapper">
                <h2 className="title mb-0">No false promises</h2>
                <h1 className="title sub mb-0">Realistic Income</h1>
                <p>
                  Believe in yourself to <br /> Achieve what is possible for
                  you!
                </p>
                <div className="px-5">
                  <img
                    src={common.loadImg("weight-down.svg")}
                    width="368"
                    height="343"
                    alt="weight1"
                    className="weight-down-img"
                  />
                </div>
                <img
                  src={common.loadImg("weight-up.svg")}
                  width="397"
                  height="688"
                  alt="weight1"
                  className="weight-up-img"
                />
              </div>
              <div className="agent-signup-right">
                <img
                  src={common.loadImg("logo-web.svg")}
                  width="115"
                  height="57"
                  alt="study-uk"
                />
              </div>
              <div>
                <p className="promoter-siginup-foot-txt">
                  Register as a Agent for Pickauni and earn a{" "}
                  <strong>realistic £50 - £100k per year.*</strong>
                </p>
                <p className="more-link-txt">
                  Click the link below to find out more
                </p>
              </div>
            </div>
            <div className="bottom-bg"></div>
            <div className="left">
              <p className="title register-title mb-5">Forgot Password</p>
              {/* <div className="d-flex justify-content-start mb-5">
                {Array(6)
                  .fill()
                  .map((i, k) => {
                    ++k;
                    return (
                      <div key={k} className="cp" onClick={() => gotoStep(k)}>
                        <p className="register-steps-label web-steps-label me-2">
                          <span
                            className={`dotbtn${
                              step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                            }`}
                            data-title={tooltip[k - 1]}
                          >
                            {k}
                          </span>
                          <span
                            className={`reg-steps-label${
                              step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                            }`}
                          >
                            {tooltip[k - 1]}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                <p
                  id="tool-tip"
                  className="et-cp"
                  data-tooltip-content="This is Tooltip"
                ></p>
                <ReactTooltip
                  anchorId="tool-tip"
                  place={"bottom"}
                  style={{ zIndex: "999" }}
                />
              </div> */}
              <div style={{ minHeight: "40px" }}></div>
              <div className="form-wrap auth-form-wrap">
                <div className="auth-form-container">
                  <div className="text-center pb-3">
                    <div className="et-form-row form-wrap">
                      {/* <input
                      type="email"
                      name="email"
                      placeholder="Username or Email"
                      className="form-control input-email cust-input"
                      maxLength={40}
                      value={fields.email}
                      onChange={(e) => {
                        resetError("email");
                        handleChange(e);
                      }}
                    />
                    <div className="email-fieldcheckbox checkbox-design">
                      <div className={"input-tick-active"}></div>
                    </div>
                    <p className="error-txt">{error ? error : errors.email}</p> */}
                      <input
                        type="email"
                        name="email"
                        className="form-control input-email cust-input"
                        value={fields.email}
                        placeholder="Enter your email address"
                        onChange={(e) => {
                          setError(null);
                          handleChange(e);
                        }}
                      />
                      <p className="error-txt text-start">
                        {error ? error : errors.email}
                      </p>
                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className={
                            loading
                              ? "form-control form-btn promoter-auth-btn"
                              : "form-control form-btn promoter-auth-btn"
                          }
                          onClick={onSubmit}
                          disabled={loading}
                        >
                          {loading ? "Processing..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {location?.pathname === "/student/password/forgot" && (
        <div className="auth-wrap student-auth-wrap">
          <div className="auth-logo-wrap">
            <a href={config.home_page}>
              <img
                src={common.loadImg("logo-purple.svg")}
                width="113"
                height="100"
                alt="study-uk"
              />
            </a>
          </div>
          <div className="signin-right student-signup-right">
            <div>
              <img
                // src="/imgs/app-section-img2.png"
                src={common.loadImg("app-section-img2.png")}
                width="500"
                height="520"
                alt="study-uk"
                className="novelty-app-img"
              />
            </div>
            <div>
              <div className="right-content">
                <p className="download-title-txt">
                  "Download the Pickauni App: University Applications Made Easy"
                </p>
                <p className="download-txt1">Just for students.</p>
                <p className="download-txt2">
                  Experience a hassle-free and seamless application process for
                  your guaranteed University offer.
                </p>
                <p className="adv-txt1">
                  Advantages associated with applying through Pickauni:
                </p>
                <ul className="download-list-txt">
                  <li>
                    Enjoy complimentary meals for life as a student at popular
                    restaurants and takeaways.
                  </li>
                  <li>
                    Personalised, one-to-one support from experienced student
                    counsellors
                  </li>
                  <li>
                    Assistance with student finance applications and
                    eligibility.
                  </li>
                  <li>
                    Support with personal statements, work references, CVs, and
                    other application materials.
                  </li>
                  <li>A guaranteed offer within 72 hours.</li>
                </ul>
                <p className="app-link-wrap">
                  <a
                    href="https://apps.apple.com/us/app/novelty-card/id1628865738"
                    target="_blank"
                  >
                    <img
                      src={common.loadImg("appstore-btn.svg")}
                      width="132"
                      height="44"
                      alt="app-btn"
                      className="me-1"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.noveltycarduser&pli=1"
                    target="_blank"
                  >
                    <img
                      src={common.loadImg("playstore-btn.svg")}
                      width="132"
                      height="44"
                      alt="app-btn"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="left">
            <p className="title">
              <span>Forgot Password</span>
            </p>
            <div>
              <div className="et-form-row mt-5">
                <input
                  type="email"
                  name="email"
                  className="form-control input-email cust-input"
                  value={fields.email}
                  placeholder="Enter your email address"
                  onChange={(e) => {
                    setError(null);
                    handleChange(e);
                  }}
                />
                {/* <div className=" email-fieldcheckbox checkbox-design">
                <div className="input-tick"></div>
              </div> */}
                <p className="error-txt">{error ? error : errors.email}</p>
              </div>

              <div className="d-flex justify-content-center mt-4">
                <button
                  className="form-control form-btn student-auth-btn"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Submit"}
                </button>
              </div>
            </div>
            <p className="policy-txt">
              We never share your data without permission. <br />
              Our privacy and cookie policies
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
