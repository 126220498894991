import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import Lightbox from "react-18-image-lightbox";
import { Link } from "react-router-dom";
// includes
import FieldSelect from "elements/fieldSelect";
import StatusBar from "elements/statusBar";
import { api, common } from "helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Three(props) {
  // state
  let tempData = common.tempDataGet();
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [selectRender, setSelectRender] = useState({
    country: "yes",
    province: "yes",
    city: "no",
  });
  const [logoErr, setLogoErr] = useState("");
  const [fieldsB, setFieldsB] = useState({
    businessLogo: "",
    businessLogoFile: [],
  });
  const [galleryImage, setGalleryImage] = useState(null);
  // form
  const formFields = {
    businessName: {
      validate: ["req#Name/Business Name is required"],
    },
    designation: {
      validate: [],
      //validate: ["req#Designation is required"],
    },
    businessAddress: {
      validate: ["req#Personal/Business Address is required!"],
    },

    businessContactNo: {
      validate: [
        "req#Personal/Business Contact Number is required!",
        "min:5#Not a valid contact number",
        "max:15#Not a valid contact number",
      ],
      allow: "number",
    },
    businessRegNumber: {
      validate: [],
    },
    noOfStudentRecruitLastYear: {
      validate: [],
      allow: "number",
    },
    noOfStaffWorking: {
      validate: [],
      allow: "number",
    },
    countryOfOperation: {
      validate: [],
      obj: "value",
    },
    province: {
      validate: [],
      obj: "value",
    },
    city: {},
    recruitMethod: {
      validate: [],
    },
    description: {
      validate: [],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    initData();
    console.log("tempData", tempData);
  }, []);

  // api
  const initData = () => {
    getProfile();
  };

  // Handler
  const onSubmit = () => {
    // if(fieldsB.businessLogoFile == "" && fieldsB.businessLogoFile && fieldsB.businessLogoFile.length == 0 ){
    //   setLogoErr("Business Logo is required!")
    // } else {
    //   setLogoErr("");
    // }
    let isValid = handleSubmit();
    if (isValid) {
      // if(fieldsB.businessLogoFile == "" && fieldsB.businessLogoFile && fieldsB.businessLogoFile.length == 0 ){
      //   setLogoErr("Business Logo is required!")
      // } else {
      setLogoErr("");
      register();
      // }
    }
  };

  // Api
  const register = async () => {
    setLoading(true);
    // params
    let params = common.reParse(fields);
    params.countryOfOperation = params.countryOfOperation?.value;
    params.province = params.province?.value;
    params.city = params.city?.value;

    // file upload
    let error = "";
    await Promise.all(
      ["businessLogo"].map(async (ip, kp) => {
        if (fieldsB[`${ip}File`].length > 0) {
          let result = await uploadFile(fieldsB[`${ip}File`]);
          if (result.status !== 200) {
            error = result.data.error;
          } else {
            params[ip] = result.data.link[0];
          }
        }
      })
    );

    if (error) {
      common.notify("E", error);
      setLoading(false);
    } else {
      // call
      let data = {
        url: "agent_profile",
        method: "PUT",
        auth: "temp",
        body: params,
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", "Business details has been updated");
          props?.onSuccess();
        } else if (res.status === 500) {
          common.notify("E", res.data.error);
        }
        setLoading(false);
      });
    }
  };

  const getProfile = () => {
    let data = {
      url: "agent_profile",
      method: "GET",
      auth: "temp",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = common.reParse(res.data);
        // select
        if (!result.mobileCode) {
          result.mobileCode = "+44";
        }
        result.countryOfOperation = {
          label: result.countryOfOperation?.name || "United Kingdom",
          value: result.countryOfOperation?._id || "GB",
        };
        result.province = result.province
          ? {
              label: result.province?.name,
              value: result.province?._id,
            }
          : null;
        result.city = result.city
          ? {
              label: result.city?.name,
              value: result.city?._id,
            }
          : null;

        setFieldsB((prev) => ({
          ...prev,
          businessLogo: result.businessLogo,
        }));

        setMultiValue(result);
        setSelectRender((prev) => ({
          ...prev,
          city: result.province ? "yes" : "no",
        }));
        getCountry();
      } else {
        setStatus("error");
      }
    });
  };

  const getCountry = () => {
    let data = {
      url: "common_country",
      query: "?all=show",
      method: "GET",
      auth: "temp",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setCountry(res.data.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const onFileChange = (e) => {
    const { name, files } = e.target;
    setFieldsB((prev) => ({
      ...prev,
      [name]: [...files],
    }));
    setLogoErr("");
  };

  const deleteFile = (key, name) => {
    setFieldsB((prev) => ({
      ...prev,
      [name]: prev[name].filter((i, k) => k !== key),
    }));
  };

  const uploadFile = async (arr) => {
    var formdata = new FormData();
    arr.map((i) => {
      formdata.append("file", i);
    });
    formdata.append("type", "agent");

    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      auth: "temp",
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  return (
    <div className="row">
      <div className="col-12">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="right-content-width remove-border">
                <div className="">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <input
                          type="text"
                          name="businessName"
                          placeholder="Name/Business Name *"
                          value={fields.businessName}
                          className="form-control auth-template-input"
                          onChange={handleChange}
                        />
                      </div>
                      <p className="error-txt">{errors.businessName}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <input
                          type="text"
                          name="designation"
                          placeholder="Designation/Occupation"
                          value={fields.designation}
                          onChange={handleChange}
                          className="form-control auth-template-input"
                        />
                      </div>
                      <p className="error-txt">{errors.designation}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <input
                          type="text"
                          name="businessAddress"
                          placeholder="Personal/Business Address"
                          value={fields.businessAddress}
                          className="form-control auth-template-input"
                          onChange={handleChange}
                        />
                      </div>
                      <p className="error-txt">{errors.businessAddress}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="signin-form-row">
                            <div className="auth-form-row">
                              <PhoneInput
                                inputProps={{
                                  name: "businessContactNo",
                                }}
                                value={fields.businessContactNo}
                                country={"gb"}
                                onChange={(phone) =>
                                  handleChange({
                                    target: {
                                      name: "businessContactNo",
                                      value: phone,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <p className="error-txt">{errors.businessContactNo}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row  ">
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <input
                          type="text"
                          name="businessRegNumber"
                          placeholder="Business Registration Number (If applicable)"
                          value={fields.businessRegNumber}
                          className="form-control auth-template-input"
                          onChange={handleChange}
                        />
                      </div>
                      <p className="error-txt">{errors.businessRegNumber}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <input
                          type="text"
                          name="recruitMethod"
                          placeholder="Your Recruitment Method (Optional)"
                          value={fields.recruitMethod}
                          className="form-control auth-template-input"
                          onChange={handleChange}
                        />
                      </div>
                      <p className="error-txt">{errors.recruitMethod}</p>
                    </div>
                  </div>

                  <div className="row  ">
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <input
                          type="text"
                          name="noOfStudentRecruitLastYear"
                          placeholder="Number of students recruited for last year (If applicable)"
                          value={fields.noOfStudentRecruitLastYear}
                          className="form-control auth-template-input"
                          onChange={handleChange}
                        />
                      </div>
                      <p className="error-txt"></p>
                    </div>
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <input
                          type="text"
                          name="noOfStaffWorking"
                          placeholder="Number of staffs working (If applicable)"
                          value={fields.noOfStaffWorking}
                          className="form-control auth-template-input"
                          onChange={handleChange}
                        />
                      </div>
                      <p className="error-txt"></p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <input
                          type="text"
                          name="otherCountryBranch"
                          placeholder="Other countries or branches (If applicable)"
                          value={fields.otherCountryBranch}
                          className="form-control auth-template-input"
                          onChange={handleChange}
                        />
                      </div>
                      <p className="error-txt">{errors.otherCountryBranch}</p>
                    </div>

                    <div className="col-md-6">
                      <div className="signin-form-row auth-form-row">
                        <div className="auth-select-wrap">
                          <FieldSelect
                            api="common_country"
                            query="?all=show"
                            get="name"
                            set="code"
                            value={fields.countryOfOperation}
                            placeholder="Country of Operation"
                            render={selectRender.country}
                            clearable={true}
                            findValue={false}
                            multi={false}
                            auth="temp"
                            onSelect={(data) => {
                              setMultiValue({
                                countryOfOperation: data,
                                province: null,
                                city: null,
                              });
                              setSelectRender((prev) => ({
                                ...prev,
                                province: data === null ? "empty" : new Date(),
                                city: "empty",
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <p className="error-txt">{errors.countryOfOperation}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <div className="auth-select-wrap">
                          <FieldSelect
                            api="common_state"
                            query={`/${fields?.countryOfOperation?.value}?&offset=1&limit=1000`}
                            get="name"
                            set="_id"
                            value={fields.province}
                            placeholder="Province"
                            render={selectRender.province}
                            clearable={true}
                            findValue={false}
                            multi={false}
                            auth="temp"
                            onSelect={(data) => {
                              setMultiValue({
                                province: data,
                                city: null,
                              });
                              setSelectRender((prev) => ({
                                ...prev,
                                city: data === null ? "empty" : new Date(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <p className="error-txt">{errors.province}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <div className="auth-select-wrap">
                          <FieldSelect
                            api="common_city"
                            query={`/${fields?.countryOfOperation?.value}/${fields?.province?.value}?&offset=1&limit=1000`}
                            get="name"
                            set="_id"
                            value={fields.city}
                            placeholder="City"
                            render={selectRender.city}
                            clearable={true}
                            findValue={false}
                            multi={false}
                            auth="temp"
                            onSelect={(data) => {
                              setMultiValue({
                                city: data,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <p className="error-txt">{errors.city}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="signin-form-row">
                        <textarea
                          rows={2}
                          name="description"
                          placeholder="Description (Optional)"
                          className="form-control auth-template-input"
                          value={fields.description}
                          onChange={handleChange}
                        />
                      </div>
                      <p className="error-txt">{errors.description}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                        type="file"
                        name="businessLogoFile"
                        className="form-control auth-file-input"
                        placeholder="Business Logo (If applicable)"
                        multiple={false}
                        value={""}
                        accept="image/*"
                        onChange={(e) => onFileChange(e)}
                      />
                      <p className="error-txt">{logoErr}</p>
                      {/* already uploaded */}
                      {fieldsB.businessLogo &&
                        fieldsB.businessLogoFile.length === 0 && (
                          <div className="d-flex align-items-center justify-content-between pt-3">
                            <p
                              className="agt-proof-file mb-0 et-cp"
                              onClick={() =>
                                setGalleryImage(
                                  common.cloudImg(fieldsB.businessLogo)
                                )
                              }
                            >
                              <Link to="">
                                {common.fileName(fieldsB.businessLogo)}
                              </Link>
                            </p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={() =>
                                setFieldsB((prev) => ({
                                  ...prev,
                                  businessLogo: "",
                                }))
                              }
                            />
                          </div>
                        )}
                      {/* newly uploaded */}
                      {fieldsB.businessLogoFile.map((cI, cK) => (
                        <div
                          className="d-flex align-items-center justify-content-between py-2"
                          key={cK}
                        >
                          <p
                            className="agt-proof-file mb-0 et-cp"
                            onClick={() =>
                              setGalleryImage(URL.createObjectURL(cI))
                            }
                          >
                            <Link to="" className="auth-proof-file">
                              {cI.name}
                            </Link>
                          </p>
                          <img
                            src={common.loadImg("delete-icn-white.svg")}
                            className="et-cp"
                            onClick={() => deleteFile(cK, "businessLogoFile")}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 ms-auto">
                      <button
                        className="form-control form-btn promoter-auth-btn"
                        onClick={onSubmit}
                        disabled={loading}
                      >
                        {!loading ? "Next" : "Processing"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {galleryImage && (
        <Lightbox
          mainSrc={galleryImage}
          onCloseRequest={() => setGalleryImage(null)}
        />
      )}
    </div>
    // <div className="row">
    //   <div className="col-12 agent-signup-wrap py-5">
    //     {status !== "success" ? (
    //       <StatusBar status={status} />
    //     ) : (
    //       <div className="row">
    //         <div className="col-md-10 mx-auto">
    //           <div className="right-content-width remove-border">
    //             <p className="ref-heading1">Register as an agent</p>
    //             <div className="agent-box-body">
    //               <div className="row">
    //                 <div className="col-md-6">
    //                   <label className="label-padding">Name/Business Name<span className="btn-red-txt"> *</span></label>
    //                   <div className="signin-form-row">
    //                     <input
    //                       type="text"
    //                       name="businessName"
    //                       placeholder="Enter the name or business name"
    //                       value={fields.businessName}
    //                       className="form-control"
    //                       onChange={handleChange}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.businessName}</p>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <label className="label-padding">Designation/Occupation</label>
    //                   <div className="signin-form-row">
    //                     <input
    //                       type="text"
    //                       name="designation"
    //                       placeholder="Enter the designation or occupation"
    //                       value={fields.designation}
    //                       onChange={handleChange}
    //                       className="form-control"
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.designation}</p>
    //                 </div>
    //               </div>

    //               <div className="row  ">
    //                 <div className="col-md-6">
    //                   <label className="label-padding">Personal/Business Address<span className="btn-red-txt"> *</span></label>
    //                   <div className="signin-form-row">
    //                     <input
    //                       type="text"
    //                       name="businessAddress"
    //                       placeholder="Enter the personal or business address"
    //                       value={fields.businessAddress}
    //                       className="form-control"
    //                       onChange={handleChange}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.businessAddress}</p>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <label className="label-padding">
    //                     Personal/Business Contact <span className="btn-red-txt"> *</span>
    //                   </label>
    //                   <div className="row">
    //                     <div className="col-md-12 col-sm-12">
    //                       {/* <input
    //                         type="text"
    //                         name="businessContactNo"
    //                         placeholder="Enter the contact number"
    //                         className="form-control"
    //                         value={fields.businessContactNo}
    //                         onChange={handleChange}
    //                       /> */}
    //                       <div className="signin-form-row">
    //                         <PhoneInput
    //                           inputProps={{
    //                             name: "businessContactNo",
    //                             // required: true,
    //                             // autoFocus: true,
    //                           }}
    //                           value={fields.businessContactNo}
    //                           country={"gb"}
    //                           onChange={(phone) =>
    //                             handleChange({
    //                               target: {
    //                                 name: "businessContactNo",
    //                                 value: phone,
    //                               },
    //                             })
    //                           }
    //                         />
    //                       </div>
    //                     </div>
    //                     <p className="error-txt">{errors.businessContactNo}</p>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="row  ">
    //                 <div className="col-md-6">
    //                   <label className="label-padding">
    //                     Business Registration Number <span className="applicable-text">(If applicable)</span>
    //                   </label>
    //                   <div className="signin-form-row">
    //                     <input
    //                       type="text"
    //                       name="businessRegNumber"
    //                       placeholder="Enter the registration number"
    //                       value={fields.businessRegNumber}
    //                       className="form-control"
    //                       onChange={handleChange}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.businessRegNumber}</p>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <label className="label-padding">
    //                     Your Recruitment Method
    //                   </label> <span className="applicable-text">(Optional)</span>
    //                   <div className="signin-form-row">
    //                     <input
    //                       type="text"
    //                       name="recruitMethod"
    //                       placeholder="Enter the requirement method"
    //                       value={fields.recruitMethod}
    //                       className="form-control"
    //                       onChange={handleChange}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.recruitMethod}</p>
    //                 </div>
    //               </div>

    //               <div className="row  ">
    //                 <div className="col-md-6">
    //                   <label className="label-padding">
    //                     Number of students recruited for last year <span className="applicable-text">(If applicable)</span>
    //                   </label>
    //                   <div className="signin-form-row">
    //                     <input
    //                       type="text"
    //                       name="noOfStudentRecruitLastYear"
    //                       placeholder="Enter last year recruitment"
    //                       value={fields.noOfStudentRecruitLastYear}
    //                       className="form-control"
    //                       onChange={handleChange}
    //                     />
    //                   </div>
    //                   <p className="error-txt"></p>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <label className="label-padding">
    //                     Number of staffs working <span className="applicable-text">(If applicable)</span>
    //                   </label>
    //                   <div className="signin-form-row">
    //                     <input
    //                       type="text"
    //                       name="noOfStaffWorking"
    //                       placeholder="Enter the staff working"
    //                       value={fields.noOfStaffWorking}
    //                       className="form-control"
    //                       onChange={handleChange}
    //                     />
    //                   </div>
    //                   <p className="error-txt"></p>
    //                 </div>
    //               </div>
    //               <div className="row">
    //                 <div className="col-md-6">
    //                   <label className="label-padding">
    //                     Other countries or branches <span className="applicable-text">(If applicable)</span>
    //                   </label>
    //                   <div className="signin-form-row">
    //                     <input
    //                       type="text"
    //                       name="otherCountryBranch"
    //                       placeholder="Enter the branches"
    //                       value={fields.otherCountryBranch}
    //                       className="form-control"
    //                       onChange={handleChange}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.otherCountryBranch}</p>
    //                 </div>

    //                 <div className="col-md-6">
    //                   <label className="label-padding">
    //                     Country of Operation
    //                   </label>
    //                   <div className="signin-form-row">
    //                     <FieldSelect
    //                       api="common_country"
    //                       query="?all=show"
    //                       get="name"
    //                       set="code"
    //                       value={fields.countryOfOperation}
    //                       placeholder="Please select the country"
    //                       render={selectRender.country}
    //                       clearable={true}
    //                       findValue={false}
    //                       multi={false}
    //                       auth="temp"
    //                       onSelect={(data) => {
    //                         setMultiValue({
    //                           countryOfOperation: data,
    //                           province: null,
    //                           city: null,
    //                         });
    //                         setSelectRender((prev) => ({
    //                           ...prev,
    //                           province: data === null ? "empty" : new Date(),
    //                           city: "empty",
    //                         }));
    //                       }}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.countryOfOperation}</p>
    //                 </div>
    //               </div>

    //               <div className="row">
    //                 <div className="col-md-6">
    //                   <label className="label-padding">Province</label>
    //                   <div className="signin-form-row">
    //                     <FieldSelect
    //                       api="common_state"
    //                       query={`/${fields?.countryOfOperation?.value}?&offset=1&limit=1000`}
    //                       get="name"
    //                       set="_id"
    //                       value={fields.province}
    //                       placeholder="Please select the province"
    //                       render={selectRender.province}
    //                       clearable={true}
    //                       findValue={false}
    //                       multi={false}
    //                       auth="temp"
    //                       onSelect={(data) => {
    //                         setMultiValue({
    //                           province: data,
    //                           city: null,
    //                         });
    //                         setSelectRender((prev) => ({
    //                           ...prev,
    //                           city: data === null ? "empty" : new Date(),
    //                         }));
    //                       }}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.province}</p>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <label className="label-padding">City</label>
    //                   <div className="signin-form-row">
    //                     <FieldSelect
    //                       api="common_city"
    //                       query={`/${fields?.countryOfOperation?.value}/${fields?.province?.value}?&offset=1&limit=1000`}
    //                       get="name"
    //                       set="_id"
    //                       value={fields.city}
    //                       placeholder="Please select the city"
    //                       render={selectRender.city}
    //                       clearable={true}
    //                       findValue={false}
    //                       multi={false}
    //                       auth="temp"
    //                       onSelect={(data) => {
    //                         setMultiValue({
    //                           city: data,
    //                         });
    //                       }}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.city}</p>
    //                 </div>
    //               </div>
    //               <div className="row">
    //                 <div className="col-md-6">
    //                   <label className="label-padding">Description <span className="applicable-text">(Optional)</span></label>
    //                   <div className="signin-form-row">
    //                     <textarea
    //                       rows={5}
    //                       name="description"
    //                       placeholder="Enter the description"
    //                       className="form-control"
    //                       value={fields.description}
    //                       onChange={handleChange}
    //                     />
    //                   </div>
    //                   <p className="error-txt">{errors.description}</p>
    //                 </div>

    //                 <div className="col-md-6">
    //                   <label className="label-padding">Business Logo <span className="applicable-text">(If applicable)</span></label>
    //                   <input
    //                     type="file"
    //                     name="businessLogoFile"
    //                     className="form-control"
    //                     multiple={false}
    //                     value={""}
    //                     accept="image/*"
    //                     onChange={(e) => onFileChange(e)}
    //                   />
    //                   <p className="error-txt">{logoErr}</p>
    //                   {/* already uploaded */}
    //                   {fieldsB.businessLogo &&
    //                     fieldsB.businessLogoFile.length === 0 && (
    //                       <div className="d-flex align-items-center justify-content-between pt-3">
    //                         <p
    //                           className="agt-proof-file mb-0 et-cp"
    //                           onClick={() =>
    //                             setGalleryImage(
    //                               common.cloudImg(fieldsB.businessLogo)
    //                             )
    //                           }
    //                         >
    //                           <Link to="">
    //                             {common.fileName(fieldsB.businessLogo)}
    //                           </Link>
    //                         </p>
    //                         <img
    //                           src={common.loadImg("iconly-dark-delete.svg")}
    //                           className="et-cp"
    //                           onClick={() =>
    //                             setFieldsB((prev) => ({
    //                               ...prev,
    //                               businessLogo: "",
    //                             }))
    //                           }
    //                         />
    //                       </div>
    //                     )}
    //                   {/* newly uploaded */}
    //                   {fieldsB.businessLogoFile.map((cI, cK) => (
    //                     <div
    //                       className="d-flex align-items-center justify-content-between pt-3"
    //                       key={cK}
    //                     >
    //                       <p
    //                         className="agt-proof-file mb-0 et-cp"
    //                         onClick={() =>
    //                           setGalleryImage(URL.createObjectURL(cI))
    //                         }
    //                       >
    //                         <Link to="">{cI.name}</Link>
    //                       </p>
    //                       <img
    //                         src={common.loadImg("iconly-dark-delete.svg")}
    //                         className="et-cp"
    //                         onClick={() => deleteFile(cK, "businessLogoFile")}
    //                       />
    //                     </div>
    //                   ))}
    //                 </div>
    //               </div>

    //               <div className="row">
    //                 <div className="col-lg-12">&nbsp;</div>
    //                 <div className="col-lg-12 register-step-agent-footer">
    //                   <button
    //                     className="btn-default"
    //                     onClick={onSubmit}
    //                     disabled={loading}
    //                   >
    //                     {!loading ? "Next" : "Processing"}
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     )}
    //   </div>
    //   {galleryImage && (
    //     <Lightbox
    //       mainSrc={galleryImage}
    //       onCloseRequest={() => setGalleryImage(null)}
    //     />
    //   )}
    // </div>
  );
}

export default Three;
